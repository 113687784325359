import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { addMinutes, format, isToday, startOfDay } from 'date-fns';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebsiteService } from '../api-services';
import { PASSWORD_SPECIAL_SYMBOLS } from '../data/config-constants';
import { Address, ILangContentRequiredError } from '../models';
import { VoucherService } from '../services';


// @dynamic
export class BoxValidators {
  static passwordComplexity(control: AbstractControl): ValidationErrors | null {
    const symbols = PASSWORD_SPECIAL_SYMBOLS;
    const passwordRe = new RegExp(`^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[${symbols}])[\\w${symbols}]{8,64}$`);
    return passwordRe.test(control.value) ? null : { weakPassword: { value: control.value } };
  }

  // todo do we need this?
  static unitRequired(control: AbstractControl): ValidationErrors | null {
    const formGroup = control.parent?.controls;

    if (!formGroup) {
      return null;
    }

    const name = Object.keys(formGroup).find(name => control === formGroup[ name ]) || null;

    return !control.parent.get(`${name}Unit`)?.value ? { unitRequired: name } : null;
  }

  static matchPassword(passwordFieldName: string, confirmPasswordFieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const passwordControl = control.get(passwordFieldName);
      const passwordValue = passwordControl.value;
      const confirmPasswordControl = control.get(confirmPasswordFieldName);
      const confirmPasswordValue = confirmPasswordControl.value;

      confirmPasswordControl.setErrors(
        confirmPasswordValue
          ? (passwordValue && confirmPasswordValue && passwordValue !== confirmPasswordValue ? { mismatch: { control: passwordFieldName } } : null)
          : { required: { control: passwordFieldName } }
      );

      return null;
    };
  }

  static storeUrl(baseDomain: string): ValidatorFn {
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

    return (c: AbstractControl): ValidationErrors | null => {
      return regex.test(`${c.value}${baseDomain}`) ? null : { url: true };
    };
  }

  static url(control: AbstractControl): ValidationErrors | null {
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

    return !control.value || control.value.startsWith('/') || regex.test(`${control.value}`) ? null : { url: true };
  }

  static minFloat(min: number): ValidatorFn {
    min = min ?? 0;

    return (c: AbstractControl): ValidationErrors | null => {
      const floatValue = customParseFloat(c.value);

      if (floatValue >= min) {
        return null;
      }

      return { min: { valid: false, min } };
    };
  }

  static maxFloat(max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const floatValue = customParseFloat(c.value);

      if (floatValue <= max) {
        return null;
      }

      return { max: { valid: false, max } };
    };
  }

  // todo use it with percentageValidator, allow values < 1 for percentageValidator?
  static greaterThan(min: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const floatValue = customParseFloat(c.value);

      return floatValue > min ? null : { greaterThan: { min, actual: floatValue } };
    };
  }

  static minLengthArray(min: number): ValidatorFn {
    min = min ?? 0;

    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value || c.value.length >= min) {
        return null;
      }

      return { minLengthArray: { valid: false, min } };
    };
  }

  static maxLengthArray(max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value || c.value.length <= max) {
        return null;
      }

      return { maxLengthArray: { valid: false, max } };
    };
  }

  static fullAddressValidator(requiredFields?: Array<keyof Address>): ValidatorFn {
    const formattedName = 'formattedAddress';
    const defaultFields = Object.keys(new Address()).filter(f => f !== formattedName) as Array<keyof Address>;

    requiredFields = requiredFields ?? defaultFields;

    return (control: AbstractControl): ValidationErrors | null => {
      const formattedControl = control.get(formattedName);

      if (!formattedControl.value) {
        formattedControl.setErrors({ emptyAddress: true });
        return null;
      }

      const errors = requiredFields.some(f => control.get(f)?.invalid) ? { invalidAddress: true } : null;

      formattedControl.setErrors(errors);

      return null;
    };
  }

  static dateAndTimeValidator(dateFieldName: string, timeFieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateControl = control.get(dateFieldName);
      const timeControl = control.get(timeFieldName);

      if (!!dateControl.value === !!timeControl.value) {
        dateControl.setErrors(null);
        timeControl.setErrors(null);
        return null;
      }

      (!dateControl.value ? dateControl : timeControl).setErrors({ required: true  });
    };
  }

  static voucherCodeValidator(control: AbstractControl): ValidationErrors | null {
    const codeRe = /^\S*$/;
    return codeRe.test(control.value) ? null : { voucherCode: { valid: false } };
  }

  static percentageValidator(control: AbstractControl): ValidationErrors | null {
    const { value: _value } = control;
    const value = customParseFloat(_value);

    if (!value) {
      return null;
    } else if (value < 1) {
      return { minPercentage: { actual: value } };
    } else if (value > 100) {
      return { maxPercentage: { actual: value } };
    } else {
      return null;
    }
  }

  static voucherMaxUsesValidator(numberOfTimesRedeemed = 0): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      if (value && numberOfTimesRedeemed >= value) {
        return { maxUses: { valid: false } };
      }

      return null;
    };
  }

  static refundAmountValidator(totalAmount: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value || c.value <= totalAmount) {
        return null;
      }

      return { refundAmountValidator: { valid: false } };
    };
  }

  static validateVoucherCodeAvailability(voucherService: VoucherService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return voucherService
        .checkAvailability(control.value)
        .pipe(
          map(result => result.available ? {} : { server: $localize`This voucher code already exists.` })
        );
    };
  }

  static futureDateValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const date = startOfDay(new Date(control.value));
    const today = startOfDay(new Date());

    return today > date ? { server: $localize`The date value should not be earlier than ${format(today, 'd/MM/yyyy')}` } : null;
  }

  static futureTimeValidator(dateField: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      dateField = dateField ?? 'date';

      const time = +control.value.replace(':', '');
      const dateValue = control.parent?.get(dateField).value;

      if (!dateValue || !time) {
        return null;
      }

      const date = new Date(dateValue);
      const next15Minutes = addMinutes(new Date(), 15);
      const comparisonTime = +format(next15Minutes, 'HHmm');

      return isToday(date) && time < comparisonTime ? { server: $localize`Time has already passed/cannot use current time.` } : null;
    };
  }

  static futureDateTimeValidator(dateField: string, timeField: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateControl = control.get(dateField);
      const dateValue = dateControl.value;
      const timeControl = control.get(timeField);
      const timeValue = timeControl.value;

      dateControl.markAsTouched();
      timeControl.markAsTouched();

      if (dateValue) {
        const date = startOfDay(new Date(dateValue));
        const today = startOfDay(new Date());

        dateControl.setErrors(today > date ? { server: $localize`The date value should not be earlier than ${format(today, 'd/MM/yyyy')}` } : null);
      } else {
        dateControl.setErrors(null);
      }

      if (dateValue && timeValue) {
        const time = +timeValue.replace(':', '');
        const date = new Date(dateValue);
        const next15Minutes = addMinutes(new Date(), 15);
        const comparisonTime = +format(next15Minutes, 'HHmm');

        timeControl.setErrors(isToday(date) && time < comparisonTime ? { server: $localize`Time has already passed/cannot use current time.` } : null);
      } else {
        timeControl.setErrors(null);
      }

      return null;
    };
  }

  static langContentRequired(defaultLangMessage?: string): ValidationErrors | null {
    return (c: AbstractControl): ValidationErrors | null => {
      const content = c.get('languageContent');
      if (!content) {
        return;
      }
      const vals = content.value;
      const emptyLangs = Object.keys(vals).filter(key => !vals[ key ]);
      const langContentRequired: ILangContentRequiredError = { emptyLangs, defaultLangMessage };
      return emptyLangs.length === 0 ? null : { langContentRequired };
    };
  }

  static storeInfoValidator(): ValidationErrors | null {
    return (c: AbstractControl): ValidationErrors | null => {
      return c.value?.aboutUs?.content ? null : { required: 'this is required' };
    };
  }

  static domainAvailability(initDomain: string, baseDomain: string, websiteService: WebsiteService): AsyncValidatorFn {

    return (c: AbstractControl): Observable<ValidationErrors | null> => {
      const domain = c.value;

      if (domain === initDomain) {
        return of(null);
      }

      return websiteService.checkSubDomain(`${domain}${baseDomain}`)
        .pipe(
          map(resp => resp.available ? null : { domainAvailability: true }),
        );
    };
  }
}

function customParseFloat(value: string): number {
  let validValue = `${(value || 0).toString().replace(',', '.')}`;

  if (validValue.endsWith('.')) {
    validValue = `${validValue}0`;
  }

  return parseFloat(validValue);
}
