import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiGetParams, ApiResponse } from '../models';
import { BaseStoreService } from '../stores';
import { ApiService } from './api.service';

/** @deprecated */
export abstract class CrudService<T> {
  protected basePath: string;
  protected apiService: ApiService;
  protected baseEntity: any;
  protected currentPath = 'current';
  protected maxPageSize = 1000;
  protected store: BaseStoreService<T>;

  all(params?: ApiGetParams): Observable<T[]> {
    return this.apiService.get(this.basePath, params)
      .pipe(
        map((resp: any) => resp.data.map(i => this.mapEntity(i)))
      );
  }

  allPages(params?: ApiGetParams): Observable<T[]> {
    return this.all({ ...params, pageSize: this.maxPageSize });
  }

  allWithMeta(params?: ApiGetParams): Observable<ApiResponse<T[]>> {
    return this.apiService.get(this.basePath, params);
  }

  find(id: string, params?: ApiGetParams): Observable<T> {
    return this.apiService.get(`${this.basePath}/${id}`, params)
      .pipe(map((resp: any) => this.mapEntity(resp.data)));
  }

  create(data: any): Observable<T> {
    return this.apiService.post(this.basePath, data)
      .pipe(map((resp: any) => this.mapEntity(resp.data)));
  }

  update(id: string, data: any): Observable<T> {
    return this.apiService.put(`${this.basePath}/${id}`, data)
      .pipe(map((resp: any) => this.mapEntity(resp.data)));
  }

  patch(id: string, data: Partial<T>): Observable<T> {
    return this.apiService.patch<T>(`${this.basePath}/${id}`, data)
      .pipe(map((resp: any) => this.mapEntity(resp.data)));
  }

  delete(id: string): Observable<null> {
    return this.apiService.delete(`${this.basePath}/${id}`);
  }

  findCurrent(): Observable<T> {
    return this.find(this.currentPath);
  }

  updateCurrent(data: T): Observable<T> {
    return this.update(this.currentPath, data);
  }

  patchCurrent(data: Partial<T>): Observable<T> {
    return this.patch(this.currentPath, data);
  }

  updateListStore(): Observable<T[]> {
    return this.allPages()
      .pipe(
        tap(list => this.store.list = list),
      );
  }

  private mapEntity(item) {
    return typeof this.baseEntity === 'function' && this.baseEntity && new this.baseEntity().fromSource ? new this.baseEntity().fromSource(item) : item;
  }
}

