export class Address {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  countryCode?: string;
  formattedAddress?: string;
  latitude?: number | string;
  longitude?: number | string;
  placeId?: string;
  placeName?: string;
  postalCode?: any;
  province?: string;
  suburb?: string;

  constructor() {
    this.addressLine1 = '';
    this.addressLine2 = '';
    this.city = '';
    this.country = '';
    this.countryCode = '';
    this.formattedAddress = '';
    this.latitude = '';
    this.longitude = '';
    this.placeId = '';
    this.placeName = '';
    this.postalCode = '';
    this.province = '';
    this.suburb = '';
  }
}
