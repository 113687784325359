import { Injectable } from '@angular/core';
import { AddressOption } from '../../new-models/us-models';
import { CrudService } from '../index';

@Injectable({
  providedIn: 'root'
})
export class CustomerAddressService extends CrudService<AddressOption> {
  basePath = '/v1/customers/address/current';
  currentPath = 'current';

  constructor() {
    super();
  }
}
