import { inject, Injectable, Signal, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DeliveryQuote, PickupPoint, ShippingSettings } from '../../new-models/us-models';
import { ApiService } from '../../services';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  private readonly basePath = '/v1/shipping/account';

  #shippingSettings = signal<ShippingSettings>(null);

  #pickupPoints = signal<PickupPoint[]>([]);
  #pickupPointsLoaded = signal<boolean>(false);

  #deliveryQuote = signal<DeliveryQuote>(null)
  #deliveryQuoteLoaded = signal(false);

  #apiService = inject(ApiService);
  #cartService = inject(CartService);

  constructor() { }

  set pickupPoints(v: PickupPoint[]) {
    this.#pickupPoints.set(v);
  }

  get pickupPoints(): Signal<PickupPoint[]> {
    return this.#pickupPoints.asReadonly();
  }

  set pickupPointsLoaded(v: boolean) {
    this.#pickupPointsLoaded.set(v);
  }

  get pickupPointsLoaded(): Signal<boolean> {
    return this.#pickupPointsLoaded.asReadonly();
  }

  get deliveryQuote(): Signal<DeliveryQuote> {
    return this.#deliveryQuote.asReadonly();
  }

  set deliveryQuote(v: DeliveryQuote) {
    this.#deliveryQuote.set(v);
  }

  get deliveryQuoteLoaded(): Signal<boolean> {
    return this.#deliveryQuoteLoaded.asReadonly();
  }

  set deliveryQuoteLoaded(v: boolean) {
    this.#deliveryQuoteLoaded.set(v);
  }

  get shippingSettings(): Signal<ShippingSettings> {
    return this.#shippingSettings.asReadonly();
  }

  set shippingSettings(v: ShippingSettings) {
    this.#shippingSettings.set(v);
  }

  initPickupPoints(): Observable<PickupPoint[]> {
    this.pickupPointsLoaded = false;

    return this.getPickupPoints()
      .pipe(
        tap(points => {
          this.pickupPoints = points;
          this.pickupPointsLoaded = true;
        }),
      );
  }

  initDeliveryQuote(): Observable<DeliveryQuote> {
    this.deliveryQuoteLoaded = false;

    const order = this.#cartService.current();

    return this.getDeliveryQuote(order.id)
      .pipe(
        tap(quote => {
          this.deliveryQuote = quote;
          this.deliveryQuoteLoaded = true;
        }),
      );
  }

  initShippingSetting(): Observable<ShippingSettings> {
    return this.getShippingSettings()
      .pipe(
        tap(settings => {
          this.shippingSettings = settings;
        }),
      );
  }

  getPickupPoints(): Observable<PickupPoint[]> {
    return this.#apiService.get(`${this.basePath}/pickup`)
      .pipe(
        map(response => response.data),
      );
  }

  getDeliveryQuote(orderId: string): Observable<DeliveryQuote> {
    return this.#apiService.get(`${this.basePath}/${orderId}/delivery`)
      .pipe(
        map(response => response.data),
      );
  }

  getShippingSettings(): Observable<ShippingSettings> {
    return this.#apiService.get(`${this.basePath}/methods`)
      .pipe(
        map(response => response.data),
      );
  }
}
