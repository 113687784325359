import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  downloadCsv(fileName: string, csvContent: string): void {
    const file = new File([ csvContent ], `${fileName}.csv`, { type: 'text/plain;charset=utf-8' });
    saveAs(file);
  }

  downloadExcel(fileName: string, excel: Blob): void {
    const blob = new Blob([ excel ], { type: 'application/vnd.ms.excel' });
    const file = new File([ blob ], `${fileName}.xlsx`, { type: 'application/vnd.ms.excel' });
    saveAs(file);
  }
}
