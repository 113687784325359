const productFilters = [
  'LowStock',
  'OutOfStock',
  'Hidden',
  'Discounted',
  'Featured',
  'ProductRibbons',
  'ShipsInternationally'
] as const;

const orderStatusFilters = [
  '',
  'Expired',
  'Confirmed',
  'Completed',
  'Pending',
] as const;

export type OrderStatusFilter = typeof orderStatusFilters[ number ];

export const listQueryParams = [
  'pageNumber',
  'pageSize',
  'searchTerm',
  'sortType',
  'orderType',
  'status',
  'filterType'
] as const;

export type QueryParamKey = typeof listQueryParams[ number ];
export type ListQueryParams = { [key in QueryParamKey | string]?: string | number };

export type ProductListQueryParams = ListQueryParams & {
  // todo do we need this prod?
  filterType?: typeof productFilters[ number ] | '',
  categoryId?: string,
  brandIds?: string,
};

export type OrderListQueryParams = ListQueryParams & { status?: OrderStatusFilter | '' };
