import { InjectionToken } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BoxEnvironment } from './models/app-config';

export class PaletteClasses {
  background?: string;
  text?: string;
  textContrast?: string;
}

export interface AppConfig {
  environment?: BoxEnvironment;
  apiUrl: string;
  froalaKey?: string;
  production?: boolean;
  paletteClasses?: {
    [key in ThemePalette]?: PaletteClasses
  };
  paletteColors?: {
    [key in ThemePalette]?: string;
  },
  productImagePlaceholder?: string;
}

export const BOX_APP_CONFIG = new InjectionToken<AppConfig>('app.config');
