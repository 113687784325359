import { inject, Injectable, Signal, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BOX_APP_CONFIG } from '../../common-components.config';
import { Website } from '../../new-models/us-models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  #current = signal<Website>(null);
  #currentLoaded = signal(false);

  #config = inject(BOX_APP_CONFIG, { optional: true });
  #apiService = inject(ApiService);

  constructor() {
  }

  get current(): Signal<Website> {
    return this.#current.asReadonly();
  }

  set current(v: Website) {
    this.#current.set(v);
  }

  get currentLoaded(): Signal<boolean> {
    return this.#currentLoaded.asReadonly();
  }

  set currentLoaded(v: boolean) {
    this.#currentLoaded.set(v);
  }

  loadCurrent(): Observable<Website> {
    this.currentLoaded = false;

    return this.getCurrent()
      .pipe(
        tap(website => {
          this.current = website;
          this.currentLoaded = true;
        }),
      );
  }

  getCurrent(): Observable<Website> {
    const keys = [
      'boxcommerce-dev.dev.boxcommerce.dev',
      '9to9-4312dev.boxcommerce.dev',
    ];

    const headers = this.#config.environment.production ? {} : {
      'X-BoxCommerce-Store': keys[ 1 ],
    };

    return this.#apiService.getBase('/website', {}, headers)
      .pipe(
        map((resp: { data: Website }) => resp.data),
      );
  }
}
