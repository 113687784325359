import { Address, VoucherType } from '../../models';
import { ProductReview } from '../us-models';
import { BaseDimensions } from './dimensions';
import { OrderPaymentStatus } from './order-statuses';
import { AbstractPricing } from './pricing';

export type OrderStatus = 'Completed' | 'Cancelled' | 'Expired' | 'Draft' | 'Pending' | 'Confirmed' | 'Invalid';

export enum OrderShippingStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  ReadyForCollection = 'ReadyForCollection',
  Scheduled = 'Scheduled',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
}

export enum OrderNoteType {
  Order = 'Order',
  Refund = 'Refund',
  Cancellation = 'Cancellation',
  Collection = 'Collection',
  Delivery = 'Delivery',
  DeliveryCancellation = 'DeliveryCancellation',
  MerchantNote = 'MerchantNote',
}

export enum OrderItemStatus {
  Limited = 'Limited',
  Available = 'Available',
  None = 'None',
}

export abstract class AbstractOrderCustomer {
  contactNumber: string;
  email: string;
  name: string;
}

export abstract class AbstractOrderMilestone {
  orderCollected: string;
  orderCompleted: string;
  orderDelivered: string;
  orderDeliveryScheduled: string;
  orderPlaced: string;
}

export abstract class AbstractOrderNote {
  message: string;
  type: OrderNoteType;
}

export abstract class AbstractOrderFxRate {
  conversionRate: number;
  currencyCode: string;
  convertedAmount: number;
}

export abstract class AbstractOrderPricing {
  currency: string;
  discountAmount: number;
  fxRate: AbstractOrderFxRate;
  refundAmount: number;
  roundedAmount: number;
  subTotalAmount: number;
  taxAmount: number;
  totalAmount: number;
  voucherAmount: number;
}

export abstract class AbstractOrderVoucher {
  name: string;
  code: string;
  type: VoucherType;
  isValid: boolean;
  errorMessage: string;
}

export abstract class AbstractOrderTax {
  enabled: boolean;
  description: string;
  rate: number;
}

//export abstract class AbstractBasicOrderItem {
//  productId: string;
//  variationId?: string;
//  name: string;
//  imageUrl: string;
//  quantity: number;
//  pricing: AbstractBasicPricing;
//}

export abstract class AbstractOrderItem {
  productId: string;
  variationId?: string;
  name: string;
  imageUrl: string;
  quantity: number;
  manufacturerCountry: string;
  id: string;
  sku: string;
  allowInternationalShipping: boolean;
  hsCode: string;
  confirmedQuantity: number;
  refundedQuantity: number;
  pricing: AbstractPricing;
  dimensions: BaseDimensions;
  voucherApplied: boolean
  hasError: boolean;
  itemStockStatus: OrderItemStatus;
  expectedReadyTime: string;
  attributes: Record<string, string>;
  productReview: ProductReview;
}

export abstract class AbstractOrderPayment {
  accountId: string;
  amount: number;
  configId: string;
  currency: string;
  method: string;
  name: string;
  reference: string;
  serviceProvider: string;
}

export type OrderShippingType = 'DOMESTIC' | 'INTERNATIONAL' | 'COLLECTION';

export abstract class AbstractDeliveryRating {
  rating: number;
  comment?: string;
}

export abstract class AbstractOrderShipping {
  accountId: string;
  configId: string;
  contactDetails: string;
  cost: number;
  currency: string;
  method: string;
  name: string;
  pickupDate: string;
  reference: string;
  serviceProvider: string;
  waybillReference: string;
  type: OrderShippingType;
  trackingUrl: string;
  isFree: boolean;
  deliveryRating?: AbstractDeliveryRating;
  estimatedDeliveryDate: string;
  importDuties: number;
}

//export abstract class AbstractBasicOrder {
//  lineItems: AbstractBasicOrderItem[];
//}

export abstract class AbstractOrder {
  id: string;
  reference: string;
  milestones: AbstractOrderMilestone;
  pricing: AbstractOrderPricing;
  expectedReadyTime: string;
  tax: AbstractOrderTax;
  paymentStatus: OrderPaymentStatus;
  orderStatus: OrderStatus;
  shippingStatus: OrderShippingStatus;
  customerDetails: AbstractOrderCustomer;
  timestamp: string;
  paymentDetails?: AbstractOrderPayment | null;
  shippingDetails?: AbstractOrderShipping | null;
  deliveryDetails: Address | null;
  billingAddress: Address | null;
  voucher: AbstractOrderVoucher | null;
  notes: AbstractOrderNote[];
  abstract lineItems: AbstractOrderItem[];
}