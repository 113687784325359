<div class="spinner-container flex flex-col justify-center items-center">
  <svg [class.indeterminate]="mode === 'indeterminate'" [style.width.px]="diameter" [style.height.px]="diameter"
       [attr.viewBox]="viewBox" preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true">

    <circle [attr.r]="circleRadius" cx="50%" cy="50%" class="substrate" [style.stroke-width.%]="circleStrokeWidth"
            [style]="{ stroke: substrateColor, opacity: substrateOpacity }">
    </circle>

    <circle class="progress" cx="50%" cy="50%" [attr.r]="circleRadius" [style.stroke]="color"
            [style.animation-name]="'mat-progress-spinner-stroke-rotate-'" [style.stroke-dashoffset.px]="strokeDashOffset"
            [style.stroke-dasharray.px]="strokeCircumference" [style.stroke-width.%]="circleStrokeWidth"></circle>
  </svg>
</div>
