import { getCurrencySymbol } from '@angular/common';
import { computed, Injectable, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import { Store, StoreSettings } from '../models';
import { BaseStoreService } from './base-store.service';

@Injectable({
  providedIn: 'root'
})
export class ShopStoreService extends BaseStoreService<Store> {

  constructor() {
    super();
  }

  get currency(): Signal<string> {
    return computed(() => this.currentS()?.currency);
  }

  get currency$(): Observable<string> {
    return this.current$
      .pipe(
        pluck('currency'),
      );
  }

  get currencySymbol$(): Observable<string> {
    return this.currency$
      .pipe(
        map(currency => getCurrencySymbol(currency, 'narrow')),
      );
  }
}
