import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Website } from '../../new-models/us-models';
import { AbstractAppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export abstract class AppService extends AbstractAppService {
  #document = inject(DOCUMENT);
  #titleService = inject(Title);

  protected constructor() {
    super();
  }

  setWebsiteInfo(website: Website): void {
    this.#document.getElementById('appFavicon').setAttribute('href', website.favicon);
    this.#titleService.setTitle(website.name);
  }
}
