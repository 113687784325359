import { SaleDiscountType } from './sale-discount-type';
import { BaseModel } from './base-model';
import { Pricing } from './pricing';
import { ProductAttribute } from './product-attribute';
import { ProductCategory } from './product-category';
import { ProductImage } from './product-image';
import { ProductRibbon } from './product-ribbon';
import { ProductVariation } from './product-variation';
import { ProductBrand } from "./product-brand";
import { Voucher } from './voucher';

export type StockLevel = 'OutOfStock' | 'LowStock' | 'InStock';

export enum DiscountType {
  Percentage = 'Percentage',
  Value = 'Value'
}

export enum ProductOrderType {
  Descending = 'Descending',
  Ascending = 'Ascending'
}

export enum ProductSortType {
  Name = 'Name',
  MostPopular = 'MostPopular',
  Date = 'Date',
  Price = 'Price',
  Discount = 'Discount',
}

export class Discount {
  enabled?: boolean;
  type: DiscountType;
  value: number;
}

export class ProductInventory {
  totalQuantity?: number;
  availableQuantity: number;
  reservedQuantity?: number;
}

export class ProductReviewsData {
  numberOfReviews: number;
  averageRating: number;
}

export class ProductDimensions {
  length: number;
  width: number;
  height: number;
  weight: number;
  lengthUnit: string;
  weightUnit: string;
}

export class ProductSaleDiscount {
  type: SaleDiscountType;
  value: number;
  itemLimit: number;
}

export class ProductSale {
  saleId: string;
  name: string;
  ribbon?: ProductRibbon;
  sticker?: ProductRibbon;
  discount?: ProductSaleDiscount;
  price: number;
}

export class BaseProduct extends BaseModel {
  isInWishlist: boolean;
  allowInternationalShipping?: boolean;
  categories: ProductCategory[] = [];
  brand: ProductBrand;
  vouchers: Voucher[];
  coverImageUrl: string;
  createdDate: string;
  discount?: Discount;
  enabled?: boolean;
  hasDiscountRange?: boolean;
  hasPriceRange?: boolean;
  hasVariants: boolean;
  hsCode?: string;
  manufacturerCountry?: string;
  images: ProductImage[] = [];
  inventory: ProductInventory;
  isFeatured: boolean;
  manageInventory?: null;
  maxDiscountFixedAmount?: number;
  maxDiscountPercentage?: number;
  maxDiscountType?: DiscountType;
  maxPrice?: number;
  minPrice?: number;
  name: string;
  netPrice?: number;
  pricing?: Pricing;
  reviews?: ProductReviewsData;
  ribbon: ProductRibbon;
  slug: string;
  stockLevel: StockLevel;
  stockStatus: string;
  taxAmount?: number;
  unitPrice?: number;
  variations?: ProductVariation[] = [];
  wishlist?: {
    isInWishlist: boolean;
  };
  leadTime: {
    value: number;
    unit?: string;
  };
  sameLeadTimeAsStore?: boolean;
  costPrice?: number;
}

export class Product extends BaseProduct {
  attributes?: ProductAttribute[];
  description?: string;
  digitalProduct?: boolean;
  dimensions: ProductDimensions;
  discountAmount: number;
  minimumStockThreshold?: number;
  relatedProducts?: BaseProduct[] = [];
  sku?: string;
  videoUrl?: string;
  allowInternationalShipping?: boolean;
  productType?: string;
  numberOfVariants?: number;
  availableStock?: number;
  sale?: ProductSale;

  get defaultImage(): ProductImage | null {
    return this.images ? this.images[ 0 ] : null;
  }

  get defaultVariation(): ProductVariation | null {
    return this.variations ? this.variations[ 0 ] : null;
  }

  get stockCount(): number {
    const initialStock = this.inventory.availableQuantity || 0;
    const reduceVariationsStock = (prev, current) => prev + current.stock;

    return this.variations ? this.variations.reduce(reduceVariationsStock, initialStock) : initialStock;
  }
}
