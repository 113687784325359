import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn } from '@angular/router';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AuthService, WebsiteService } from '../../api-services/api-us-services';
import { JwtService } from '../../new-services/common-services';
import { AppService } from '../../new-services/us-services';

export const authGuard: CanActivateFn = (route, state) => {
  const websiteService = inject(WebsiteService);
  const authService = inject(AuthService);
  const jwtService = inject(JwtService);
  const appService = inject(AppService);

  // to prevent opening login popup without website ready
  return toObservable(websiteService.currentLoaded)
    .pipe(
      filter(isLoaded => isLoaded),
      switchMap(() => {
        if (jwtService.hasValidToken) {
          return of(true);
        }

        authService.redirectUrl = state.url;

        return appService.navigateToLogin();
      }),
    )
};
