import { computed, effect, Injectable, Signal, signal } from '@angular/core';
import { List } from 'immutable';
import { AbstractOrderVoucher } from '../../new-models/abstract-models';
import { Cart, CartDto, CartItem, CartItemDto, CartProduct, OrderItem } from '../../new-models/us-models';

@Injectable({
  providedIn: 'root'
})
export class StoreCartService {
  emptyCart: Cart = { lineItems: [], voucherCode: null };
  cartLocalStorageKey = 'bc-shopping-cart';
  #cart = signal<Cart>(this.emptyCart);
  cart = this.#cart.asReadonly();
  cartSize: Signal<number>;
  //cartOrder: Signal<CartOrder>;

  constructor() {
    const cart = this.fromLocalStorage();

    this.cartSize = computed(() =>
      this.#cart().lineItems.reduce((prev, item) => prev + item.quantity, 0)
    );

    //this.cartOrder = computed(() => this.cart());

    if (cart) {
      this.setCart(cart);
    }

    effect(() => this.toLocalStorage(this.cart()));
  }

  /** @deprecated use saveVoucherCode instead */
  saveVoucher(voucher: AbstractOrderVoucher): void {
    this.#cart.update(c => ({ ...c, voucherCode: voucher?.code }));
  }

  saveVoucherCode(voucherCode: string | null): void {
    this.#cart.update(c => ({ ...c, voucherCode }));
  }

  clear(): void {
    this.#cart.set(this.emptyCart);
  }

  setCart(cart: Cart): void {
    this.#cart.set(cart);
    this.toLocalStorage(cart);
  }

  updateCart(cart: Partial<Cart>): void {
    this.#cart.update(c => ({ ...c, ...cart }));
  }

  addProduct(product: CartProduct, quantity = 1, variationId?: string): void {
    const existingIndex = this.cart().lineItems.findIndex(i => isProductItem(i, product.id, variationId));
    const item = createCartItem(product, variationId, quantity);

    if (existingIndex === -1) {
      this.addItem(item);
    } else {
      this.updateItem(existingIndex, item);
    }
  }

  isInCart(id: string, vId?: string): boolean {
    return this.cart().lineItems.some(i => isProductItem(i, id, vId));
  }

  private addItem(item: CartItem) {
    this.#cart.update(c => {
      const lineItems = c.lineItems;
      return { ...c, lineItems: [ ...lineItems, item ] };
    });
  }

  private updateItem(index: number, item: CartItem) {
    this.#cart.update(c => {
      const lineItems = List(c.lineItems)
        .update(index, i => ({ ...item, quantity: i.quantity + item.quantity }))
        .toArray();

      return { ...c, lineItems };
    });
  }

  private toLocalStorage(cart: CartDto): void {
    localStorage.setItem(
      this.cartLocalStorageKey,
      JSON.stringify(cart),
    );
  }

  //private transformFromOrderItem(item: OrderItem): CartItemDto {
  //  return {
  //    productId: item.id,
  //    variationId: item.id,
  //    quantity: item.quantity,
  //  };
  //}

  private fromLocalStorage(): Cart {
    const parsedCart: Cart = JSON.parse(localStorage.getItem(this.cartLocalStorageKey));

    return (parsedCart && parsedCart.lineItems) ? parsedCart : this.emptyCart;
  }
}

function isProductItem(item: CartItemDto, productId: string, variationId: string) {
  const withoutVariation = !variationId && !item.variationId;

  return item.productId === productId && (withoutVariation || item.variationId === variationId);
}

function createCartItem(product: CartProduct, variationId: string, quantity: number): CartItem {
  const variation = product.variations?.find(v => v.id === variationId);

  return {
    productId: product.id,
    variationId,
    name: variation ? `${product.name} ${variation.name}` : product.name,
    pricing: {
      unitPrice: variation?.pricing.unitPrice || product?.pricing.unitPrice,
    },
    imageUrl: variation?.images[ 0 ]?.thumbnailUrl || product.coverImageUrl,
    quantity,
  };
}

export function cartItemToOrderItem(item: OrderItem): CartItem {
  return {
    id: item.id,
    productId: item.id,
    variationId: item.id,
    name: item.name,
    imageUrl: item.imageUrl,
    quantity: item.quantity,
    pricing: {
      unitPrice: item.pricing.unitPrice,
    }
  };
}
