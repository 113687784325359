import { Injectable } from '@angular/core';
import { AuthToken } from '../../new-models/us-models';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  tokenKey = 'box-token';
  expiryKey = 'box-token-expiry';

  constructor() {
  }

  set token(token: AuthToken) {
    localStorage.setItem(this.tokenKey, token.accessToken);
    localStorage.setItem(this.expiryKey, token.expiry);
  }

  get token(): AuthToken {
    return {
      accessToken: localStorage.getItem(this.tokenKey),
      expiry: localStorage.getItem(this.expiryKey),
    };
  }

  get isTokenExpired(): boolean {
    const expiry = this.token?.expiry;

    return !expiry || new Date(expiry) < new Date();
  }

  get hasValidToken(): boolean {
    return this.token.accessToken && !this.isTokenExpired;
  }

  get validTokenString(): string | null {
    return this.hasValidToken ? this.token.accessToken : null;
  }

  destroyToken(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.expiryKey);
  }
}
