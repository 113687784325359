import { Pipe, PipeTransform } from '@angular/core';
import { AbstractAddress } from '../new-models/abstract-models/address';

@Pipe({
  name: 'addressLine',
  standalone: true
})
export class AddressLinePipe implements PipeTransform {

  transform(address: AbstractAddress, fields: (keyof AbstractAddress)[] = [
    'addressLine1',
    'addressLine2',
    'province',
    'country'
  ]): string {
    return fields.map(f => address[ f ])
      .filter(i => !!i)
      .join(', ');
  }

}
