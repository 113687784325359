import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { differenceInDays } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class RefererService {
  refKey = 'bus-document-referer';
  dateKey = 'bus-referer-date';
  refExpireIn = 3;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  get referer(): string {
    return localStorage.getItem(this.refKey);
  }

  setReferer(): void {
    const refDate = localStorage.getItem(this.dateKey);
    const ref = this.document.referrer;

    if (refDate && differenceInDays(new Date(), new Date(refDate)) > this.refExpireIn) {
      this.removeRef();
    }

    if (ref) {
      this.saveRef(ref);
    }
  }

  private saveRef(ref: string): void {
    localStorage.setItem(this.refKey, ref);
    localStorage.setItem(this.dateKey, new Date().toISOString());
  }

  private removeRef(): void {
    localStorage.removeItem(this.refKey);
    localStorage.removeItem(this.dateKey);
  }
}
