import {
  AbstractDeliveryRating,
  AbstractOrder,
  AbstractOrderCustomer,
  AbstractOrderItem,
  AbstractOrderNote
} from '../abstract-models';
import { CheckoutAction } from './payment-data';

export { orderPaymentStatus, OrderPaymentStatus } from '../abstract-models';

export type OrderStatus = 'Completed' | 'Cancelled' | 'Expired' | 'Draft' | 'Pending' | 'Confirmed' | 'Invalid';

export enum OrderItemStatus {
  Limited = 'Limited',
  Available = 'Available',
  None = 'None',
}

export class OrderCustomer extends AbstractOrderCustomer {
}

export class OrderNote extends AbstractOrderNote {
}

export class DeliveryRating extends AbstractDeliveryRating {
}

export class OrderItem extends AbstractOrderItem {
}

export class OrderPaymentDto {
  accountId: string;
}

export class MakePaymentResponse {
  checkoutTimeout: string;
  checkoutAction: CheckoutAction;
  parameters: Record<string, any>;
  completed: boolean;
  message: string;
}

export class VerifyPaymentResponse {
  completed: boolean;
  message: string;
}

export class OrderShippingDto {
  accountId: string;
  reference?: string;
  note?: string;
}

//export class BasicOrder extends AbstractBasicOrder {
//  voucherCode: string;
//}

export class Order extends AbstractOrder {
  isConfirmed?: boolean;
  expiresAt?: string;
  lineItems: OrderItem[];
  lineItemImages?: string[];
  estimatedDeliveryDate?: string;
}
