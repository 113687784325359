import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CartDto, ContactFormData, Order } from '../../new-models/us-models';
import { StoreCartService } from '../../new-services/us-services';
import { StoreService as StoreServiceLib } from '../store.service';
import { CartService } from './cart.service';
import { LanguageContent } from '../../models';


@Injectable({
  providedIn: 'root'
})
export class StoreService extends StoreServiceLib {
  protected basePath = '/v1/stores';
  currentPath = 'store';

  #cartService = inject(CartService);
  #storeCartService = inject(StoreCartService);

  constructor() {
    super();
  }

  verifyCart(cartData: CartDto): Observable<Order> {
    return this.apiService.post(`${this.basePath}/${this.currentPath}/cart/verify`, cartData)
      .pipe(
        map(resp => resp.data),
      );
  }

  contactUs(contactFormData: ContactFormData): Observable<void> {
    return this.apiService.post(`${this.basePath}/store/contact-us`, contactFormData);
  }

  getAboutUs(): Observable<LanguageContent> {
    return this.apiService.get(`${this.basePath}/${this.currentPath}/about-us`)
      .pipe(
        map(resp => resp.data),
      );
  }

  getPrivacyPolicy(): Observable<LanguageContent> {
    return this.apiService.get(`${this.basePath}/${this.currentPath}/privacy-policy`)
      .pipe(
        map(resp => resp.data),
      );
  }

  verifyAndSetCart(): Observable<Order> {
    this.#cartService.currentLoaded = false;

    return this.verifyCart(this.#storeCartService.cart())
      .pipe(
        tap(order => {
          this.#cartService.setCurrent(order);
          this.#cartService.currentLoaded = true;
        }),
      );
  }
}
