import { effect, Injectable, Signal, signal } from '@angular/core';
import { User } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  readonly #userKey = 'box-guest-user';
  //readonly #addressKey = 'box-guest-address';

  #user = signal<Partial<User>>(JSON.parse(localStorage.getItem(this.#userKey)) || {});

  constructor() {
    effect(() => {
      const user = this.user();

      if (user) {
        localStorage.setItem(this.#userKey, JSON.stringify(user));
      }
    });
  }

  // todo replace any
  get user(): Signal<Partial<User>> {
    return this.#user.asReadonly();
  }

  set data(v: Partial<User>) {
    this.#user.set(v);
  }
}
