import { inject, Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, StoreSettings } from '../models';
import { ShopStoreService } from '../stores';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends CrudService<Store> {
  protected basePath = '/v1/stores';

  // todo temporary solution
  legacyStore = inject(ShopStoreService);

  #settingsLoaded = signal(false);
  settingsLoaded = this.#settingsLoaded.asReadonly();

  #settings = signal<StoreSettings>(null);
  settings = this.#settings.asReadonly();

  constructor() {
    super();

    // todo temporary solution
    this.legacyStore.currentS = this.current;
    //effect(() => this.legacyStore.current = this.current());
  }

  loadSettings(): void {
    this.#settingsLoaded.set(false);

    this.findSettings()
      .subscribe(settings => {
        this.#settingsLoaded.set(true);
        this.#settings.set(settings);
      });
  }

  loadSettingsIfNotLoaded(): void {
    console.log(this.settingsLoaded())
    if (!this.settingsLoaded()) {
      this.loadSettings();
    }
  }

  updateSettings(data: StoreSettings): Observable<StoreSettings> {
    return this.patchSettings(data)
      .pipe(
        tap(settings => this.#settings.set(settings)),
      )
  }

  findSettings(): Observable<StoreSettings> {
    return this.find(`${this.currentPath}/settings`);
  }

  patchSettings(data: StoreSettings): Observable<StoreSettings> {
    return this.patch(`${this.currentPath}/settings`, data);
  }
}
