import { inject, Signal, signal, WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiGetParams, ApiResponse, ApiResponseMeta, LanguageContent, LanguageContentDto } from '../models';
import { ApiService } from '../services';
import { BaseStoreService } from '../stores';

// todo move to separate file
export const langContentNames = [
  'about-us',
  'delivery-details',
  'privacy-policy',
  'return-policy',
  'terms-and-conditions'
] as const
export type LangContentName = typeof langContentNames[ number ];
export type LangContentItem = { content: WritableSignal<LanguageContent>, loaded: WritableSignal<boolean> };
export type LangContentStore = Record<LangContentName, LangContentItem>;

// todo create separate AbstractStore service
export abstract class CrudService<T> {
  protected apiService = inject(ApiService);

  protected basePath: string;
  protected currentPath = 'current';
  protected maxPageSize = 1000;
  protected store: BaseStoreService<T>;

  private languageContents: LangContentStore;

  #currentLoaded = signal(false);

  #current = signal<T>(null);
  current = this.#current.asReadonly();

  #listLoaded = signal(false);
  listLoaded = this.#listLoaded.asReadonly();

  #meta = signal<ApiResponseMeta>(null);

  #list = signal<T[]>([]);
  list = this.#list.asReadonly();

  protected constructor() {
    this.languageContents = langContentNames.reduce((res, name) => ({
      ...res, [ name ]: {
        content: signal(null),
        loaded: signal(false)
      }
    }), {}) as LangContentStore;
  }

  get meta(): Signal<ApiResponseMeta> {
    return this.#meta.asReadonly();
  }

  set meta(v: ApiResponseMeta) {
    this.#meta.set(v);
  }

  get currentLoaded(): Signal<boolean> {
    return this.#currentLoaded.asReadonly();
  }

  set currentLoaded(v: boolean) {
    this.#currentLoaded.set(v);
  }

  loadCurrent(): Observable<T> {
    this.#currentLoaded.set(false);

    return this.findCurrent()
      .pipe(
        tap(item => {
          this.#currentLoaded.set(true);
          this.#current.set(item);
        }),
      );
  }

  // todo rename method
  findAndSetCurrentSync(id: string): Observable<T> {
    this.#currentLoaded.set(false);

    return this.find(id)
      .pipe(
        tap((item => {
          this.#currentLoaded.set(true);
          this.#current.set(item);
        })),
      );
  }

  findAndSetCurrent(id: string): void {
    this.findAndSetCurrentSync(id).subscribe();
  }

  deleteAndRemoveFromList(id: string): Observable<T> {
    return this.delete(id)
      .pipe(
        tap(() => this.removeFromList(id)),
      );
  }

  setCurrent(v: T): void {
    this.#current.set(v);
  }

  removeFromList(propVal: string, prop = 'id'): void {
    this.#list.update(list => list.filter(i => i[ prop ] !== propVal));
  }

  addToList(item: T): void {
    this.#list.update(list => ([ ...list, item ]));
  }

  updateInList(newItem: T, prop = 'id'): void {
    this.#list.update(list => list.map(item => item[ prop ] === newItem[ prop ] ? newItem : item));
  }

  updateOrAddToList(item: T, prop = 'id'): void {
    this.list().some(i => i[ prop ] === item[ prop ]) ? this.updateInList(item, prop) : this.addToList(item);
  }

  patchAndSetCurrent(data: Partial<T>): Observable<T> {
    return this.patchCurrent(data)
      .pipe(
        tap(current => this.setCurrent(current)),
      );
  }

  updateAndSetCurrent(data: Partial<T>): Observable<T> {
    return this.updateCurrent(data)
      .pipe(
        tap(current => this.setCurrent(current)),
      );
  }

  loadList(params?: ApiGetParams): void {
    this.#listLoaded.set(false);

    this.all(params)
      .subscribe(list => {
        this.#listLoaded.set(true);
        this.#list.set(list);
      });
  }

  loadListWithMeta(params?: ApiGetParams): Observable<ApiResponse<T[]>> {
    this.#listLoaded.set(false);

    return this.allWithMeta(params)
      .pipe(
        tap(resp => {
          this.#list.set(resp.data);
          this.meta = resp.meta;
          this.#listLoaded.set(true);
        })
      );
  }

  initList(params?: ApiGetParams): void {
    if (!this.listLoaded()) {
      this.loadList(params);
    }
  }

  initAllPages(): void {
    this.initList({ pageSize: '' });
  }

  resetCurrentList(): void {
    this.#list.update(list => ({ ...list }));
  }

  all(params?: ApiGetParams): Observable<T[]> {
    return this.apiService.get(this.basePath, params)
      .pipe(
        map((resp: ApiResponse) => resp.data)
      );
  }

  allWithMeta(params?: ApiGetParams): Observable<ApiResponse<T[]>> {
    return this.apiService.get(this.basePath, params);
  }

  find(id: string, params?: ApiGetParams): Observable<T | any> {
    return this.apiService.get(`${this.basePath}/${id}`, params)
      .pipe(
        map((resp: ApiResponse) => resp.data)
      );
  }

  findCurrent(): Observable<T> {
    return this.find(this.currentPath);
  }

  create(data: Partial<T>): Observable<T | any> {
    return this.apiService.post(this.basePath, data)
      .pipe(
        map((resp: ApiResponse) => resp.data),
      );
  }

  update(id: string, data: any): Observable<T> {
    return this.apiService.put(`${this.basePath}/${id}`, data)
      .pipe(map((resp: ApiResponse) => resp.data));
  }

  delete(id: string): Observable<T> {
    return this.apiService.delete(`${this.basePath}/${id}`);
  }

  patch(id: string, data: Partial<T>): Observable<T | any> {
    return this.apiService.patch<T>(`${this.basePath}/${id}`, data)
      .pipe(
        map((resp: ApiResponse) => resp.data),
      );
  }

  patchCurrent(data: Partial<T>): Observable<T> {
    return this.patch(this.currentPath, data);
  }

  updateCurrent(data: Partial<T>): Observable<T> {
    return this.update(this.currentPath, data);
  }

  isLangContentLoaded(name: LangContentName): Signal<boolean> {
    return this.languageContents[ name ].loaded;
  }

  getLangContent(name: LangContentName): Signal<LanguageContent> {
    return this.languageContents[ name ].content;
  }

  loadLangContent(name: LangContentName): void {
    this.findLanguageContent(name)
      .subscribe(content => {
        this.setLangContent(name, content);
        this.setLangContentLoaded(name, true);
      });
  }

  setLangContent(name: LangContentName, v: LanguageContent): void {
    this.languageContents[ name ].content.set(v);
  }

  setLangContentLoaded(name: LangContentName, v: boolean): void {
    this.languageContents[ name ].loaded.set(v);
  }

  updateLanguageContent(name: LangContentName, data: LanguageContentDto): Observable<LanguageContent> {
    return this.saveLanguageContent(name, data)
      .pipe(
        tap(content => this.setLangContent(name, content)),
      );
  }

  private saveLanguageContent(name: LangContentName, data: LanguageContentDto): Observable<LanguageContent> {
    return this.apiService.patch(`${this.basePath}/${this.currentPath}/${name}`, data)
      .pipe(
        map((resp: ApiResponse) => resp.data),
      );
  }

  private findLanguageContent(name: LangContentName): Observable<LanguageContent> {
    return this.apiService.get(`${this.basePath}/${this.currentPath}/${name}`)
      .pipe(
        map((resp: ApiResponse) => resp.data),
      );
  }
}

