import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeliveryRating, Order } from '../../new-models/us-models';
import { CrudService } from '../crud.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends CrudService<Order> {
  basePath = '/v1/basket/orders';

  constructor() {
    super();
  }

  rateDelivery(id: string, rating: DeliveryRating): Observable<DeliveryRating> {
    return this.apiService.post(`${this.basePath}/${id}/delivery/rating`, rating)
      .pipe(
        map(resp => resp.data),
      );
  }
}
