import { OrderPaymentStatus, OrderShippingStatus, OrderStatus } from '../models';
import { OrderPatchAction } from './order-actions';

const baseShipping = [
  OrderShippingStatus.ReadyForCollection,
  OrderShippingStatus.Accepted,
  OrderShippingStatus.Scheduled
];

export const actionConditions: { [key in OrderPatchAction]: any } = {
  refund: [
    {
      order: [ OrderStatus.Confirmed, OrderStatus.Completed ],
      payment: [
        OrderPaymentStatus.Paid,
        OrderPaymentStatus.Refunded,
        OrderPaymentStatus.PartiallyRefunded,
      ],
      shipping: [
        OrderShippingStatus.Pending,
        OrderShippingStatus.Cancelled,
        OrderShippingStatus.Shipped,
        ...baseShipping
      ]
    },
    {
      order: [ OrderStatus.Completed, OrderStatus.Confirmed ],
      payment: [
        OrderPaymentStatus.Paid,
        OrderPaymentStatus.Refunded,
        OrderPaymentStatus.PartiallyRefunded,
      ],
      shipping: [
        OrderShippingStatus.Delivered,
      ]
    },
  ],

  cancel: [
    {
      order: [
        OrderStatus.Pending,
        OrderStatus.Confirmed
      ],
      payment: [
        OrderPaymentStatus.Pending,
      ],
      shipping: [
        OrderShippingStatus.Pending,
        OrderShippingStatus.ReadyForCollection,
      ]
    },
    {
      order: [ OrderStatus.Confirmed ],
      payment: [
        OrderPaymentStatus.Manual,
      ],
      shipping: [
        OrderShippingStatus.Pending,
        OrderShippingStatus.Cancelled,
        ...baseShipping,
      ]
    }
  ],

  'mark-as-paid': [
    // @todo revise this conditions
    {
      order: [ OrderStatus.Confirmed, OrderStatus.Pending ],
      payment: [
        OrderPaymentStatus.Manual,
        OrderPaymentStatus.Pending,
        'PendingConfirmation',
      ],
      shipping: [
        OrderShippingStatus.Pending,
        OrderShippingStatus.Shipped,
        OrderShippingStatus.ReadyForCollection,
      ]
    },
    {
      order: [ OrderStatus.Confirmed ],
      payment: [ OrderPaymentStatus.Manual, 'PendingConfirmation' ],
      shipping: [
        OrderShippingStatus.Pending,
        OrderShippingStatus.Cancelled,
        ...baseShipping,
      ]
    },
    {
      order: [ OrderStatus.Pending ],
      payment: [
        'PendingConfirmation',
      ],
      shipping: [
        OrderShippingStatus.Delivered,
      ]
    },
  ],

  'arrange-collection': [
    {
      order: [ OrderStatus.Confirmed ],
      payment: [
        OrderPaymentStatus.Pending,
        OrderPaymentStatus.Manual,
        OrderPaymentStatus.Refunded,
        OrderPaymentStatus.PartiallyRefunded,
        OrderPaymentStatus.Paid
      ],
      shipping: [
        OrderShippingStatus.Pending,
        OrderShippingStatus.Cancelled
      ]
    },
  ],

  'capture-delivery': [
    {
      order: [ OrderStatus.Confirmed ],
      payment: [
        OrderPaymentStatus.Pending,
        OrderPaymentStatus.Manual,
        OrderPaymentStatus.Refunded,
        OrderPaymentStatus.PartiallyRefunded,
        OrderPaymentStatus.Paid
      ],
      shipping: [
        OrderShippingStatus.Pending,
        OrderShippingStatus.Cancelled
      ]
    },
  ],

  complete: [
    {
      order: [ OrderStatus.Confirmed ],
      payment: [
        OrderPaymentStatus.Paid,
        OrderPaymentStatus.Manual,
        OrderPaymentStatus.Refunded,
        OrderPaymentStatus.PartiallyRefunded,
      ],
      shipping: [
        ...baseShipping,
        OrderShippingStatus.Cancelled,
        OrderShippingStatus.Shipped,
        OrderShippingStatus.Delivered,
      ]
    },
    {
      order: [ OrderStatus.Confirmed ],
      payment: [
        OrderPaymentStatus.Refunded,
        OrderPaymentStatus.PartiallyRefunded
      ],
      shipping: [
        OrderShippingStatus.Pending,
      ]
    },
    {
      order: [ OrderStatus.Pending ],
      payment: [
        'PendingConfirmation',
      ],
      shipping: [
        OrderShippingStatus.Delivered,
      ]
    }
  ],

  'cancel/delivery': [
    {
      order: [ OrderStatus.Confirmed ],
      payment: [
        OrderPaymentStatus.Manual,
        OrderPaymentStatus.Paid,
        OrderPaymentStatus.Refunded,
        OrderPaymentStatus.PartiallyRefunded,
      ],
      shipping: [
        ...baseShipping
      ]
    },
  ],

  'download-invoice': [
    {
      order: [ ...Object.keys(OrderStatus).filter(key => key !== OrderStatus.Pending && key !== OrderStatus.Cancelled) ],
      payment: [ ...Object.keys(OrderPaymentStatus) ],
      shipping: [ ...Object.keys(OrderShippingStatus) ]
    }
  ],

  edit: [
    {
      order: [
        OrderStatus.Pending,
      ],
      payment: [
        OrderPaymentStatus.Pending,
      ],
      shipping: [
        OrderShippingStatus.Pending
      ]
    },
  ]
};
