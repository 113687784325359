import { AbstractOrderTax } from '../new-models/abstract-models';
import { Address } from './address';
import { Pricing } from './pricing';
import { ProductReview } from './product-review';
import { VoucherType } from './voucher';

export class OrderFxRate {
  conversionRate: number;
  currencyCode: string;
  convertedAmount: number;
}

export class VerifyDto {
  customerId: string;
  orderId?: string;
  voucherCode?: string;
  lineItems: { productId: string, variationId?: string, quantity: number }[];
}

export class OrderPricing {
  currency: string;
  discountAmount: number;
  fxRate: OrderFxRate;
  refundAmount: number;
  roundedAmount: number;
  subTotalAmount: number;
  taxAmount: number;
  totalAmount: number;
  voucherAmount: number;
}

export class Order {
  billingAddress: Address | null;
  channel?: ChannelType;
  customer?: OrderCustomer;
  customerId?: string;
  deliveryDetails: Address | null;
  documents?: OrderDocument[];
  expiresAt?: string;
  goodsAndServicesTaxAmount?: number;
  goodsAndServicesTaxDescription?: string;
  goodsAndServicesTaxEnabled?: boolean;
  goodsAndServicesTaxRate?: number;
  id?: string;
  isArchived?: boolean;
  isConfirmed?: boolean;
  lineItems: OrderItem[];
  milestones?: OrderMilestone;
  notes?: OrderNote[];
  orderStatus?: OrderStatus;
  paymentDetails?: OrderPayment | null;
  paymentStatus?: OrderPaymentStatus;
  pricing?: OrderPricing;
  reference?: string;
  shippingDetails?: OrderShipping | null;
  shippingStatus?: OrderShippingStatus;
  taxDescription?: string;
  taxEnabled?: boolean;
  taxRate?: number;
  timestamp?: string;
  voucher?: OrderVoucher | null;
  tax: AbstractOrderTax;
}

/** @deprecated */
export enum OrderStatus {
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Draft = 'Draft',
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  Invalid = 'Invalid',
}

/** @deprecated */
export enum OrderPaymentStatus {
  Pending = 'Pending',
  Manual = 'Manual',
  PendingConfirmation = 'PendingConfirmation',
  Failed = 'Failed',
  Paid = 'Paid',
  Refunded = 'Refunded',
  PartiallyRefunded = 'PartiallyRefunded',
}

export enum OrderShippingStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  ReadyForCollection = 'ReadyForCollection',
  Scheduled = 'Scheduled',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
}

export enum OrderNoteType {
  Order = 'Order',
  Refund = 'Refund',
  Cancellation = 'Cancellation',
  Collection = 'Collection',
  Delivery = 'Delivery',
  DeliveryCancellation = 'DeliveryCancellation',
  MerchantNote = 'MerchantNote',
}

export enum ChannelType {
  Web = 'Web',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  X = 'X',
  Google = 'Google',
  WhatsApp = 'WhatsApp',
  POS = "POS",
  Other = "Other"
}

export interface OrderCustomer {
  id?: string;
  contactNumber: string;
  email: string;
  name: string;
}

export interface OrderItem {
  allowInternationalShipping?: boolean;
  id?: string;
  productId: string;
  variationId: string;

  name: string;
  sku?: string;
  imageUrl: string;

  quantity: number;
  confirmedQuantity?: number;
  refundedQuantity?: number;

  /** @deprecated */
  unitPrice?: number;
  /** @deprecated */
  taxAmount?: number;
  /** @deprecated */
  discountAmount?: number;
  /** @deprecated */
  netPrice?: number;
  /** @deprecated */
  totalPrice?: number;

  pricing: Pricing;

  height?: number;
  length?: number;
  width?: number;
  weight?: number;

  productReview?: ProductReview;

  hasError?: boolean;
  itemStockStatus?: OrderItemStatus;
}

export type CartItem = Pick<OrderItem, 'allowInternationalShipping' | 'id' | 'productId' | 'variationId' | 'name' | 'unitPrice' | 'imageUrl' | 'quantity'>;

export type OrderItemDto = Pick<OrderItem, 'productId' | 'variationId' | 'quantity'>;

export enum OrderItemStatus {
  Limited = 'Limited',
  Available = 'Available',
  None = 'None',
}

export interface OrderPayment {
  accountId: string;
  address: Address;
  amount: number;
  configId: string;
  currency: string;
  method: string;
  name?: string;
  reference: string;
  serviceProvider: string;
}

export type OrderShippingType = 'DOMESTIC' | 'INTERNATIONAL' | 'COLLECTION';

export interface OrderShipping {
  accountId: string;
  configId: string;
  contactDetails: string;
  cost: number;
  currency: string;
  duration: string;
  estimateReference: string;
  estimatedCost: number;
  method: string;
  name?: string;
  pickupDate?: string;
  reference: string;
  serviceProvider: string;
  waybillContentUrl: string;
  waybillReference: string;
  waybillTrackingUrl: string;
  type: OrderShippingType;
  isFree?: boolean;
  deliveryRating?: DeliveryRating;
}

export class DeliveryRating {
  rating: number;
  comment?: string;
}

export class OrderNote {
  message: string;
  timestamp?: string;
  type: OrderNoteType;
}

export class OrderVoucher {
  name?: string;
  code?: string;
  type: VoucherType;
  isValid: boolean;
  errorMessage?: string;
}

export class OrderDocument {
  name?: string;
  content?: string;
}

export class OrderMilestone {
  orderCollected?: string;
  orderCompleted?: string;
  orderDelivered?: string;
  orderDeliveryScheduled?: string;
  orderPlaced?: string;
}
