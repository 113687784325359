import { Injectable } from '@angular/core';

import { CrudService } from '../crud.service';
import { Sale } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class SaleService extends CrudService<Sale> {
  protected basePath = '/v1/stores/sales';

  constructor() {
    super();
  }
}
