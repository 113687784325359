import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { AppConfig, BOX_APP_CONFIG } from '../common-components.config';
import { PixelService } from '../utils/pixel/pixel.service';

export const BOX_AZURE_KEY = new InjectionToken<string>('boxAzureKey');

type ExternalEventName = 'sign_up' | 'login' | 'start_wizard' | 'complete_wizard' | 'publish_store';

@Injectable({
  providedIn: 'root'
})
export class ExternalAnalyticsService {
  appInsights: ApplicationInsights;
  azureKey: string;

  constructor(
    private pixel: PixelService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    @Inject(BOX_AZURE_KEY) azureKey: string,
    @Optional() @Inject(BOX_APP_CONFIG) private appConfig: AppConfig,
  ) {
    this.azureKey = azureKey;
  }

  initAzure(): void {
    if (!this.appConfig?.production) {
      return;
    }

    const angularPlugin = new AngularPlugin();

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.azureKey,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAutoRouteTracking: false,
        correlationHeaderExcludedDomains: [ 'ekr.zdassets.com', 'maps.googleapis.com' ],
        extensions: [ angularPlugin ],
        extensionConfig: {
          [ angularPlugin.identifier ]: { router: this.router }
        }
      }
    });

    this.appInsights.loadAppInsights();
  }

  signUp(): void {
    this.trackEvent('sign_up');
    this.pixel.track('CompleteRegistration');
  }

  login(): void {
    this.trackEvent('login');
  }

  startOnboarding(): void {
    this.trackEvent('start_wizard');
  }

  completeOnboarding(): void {
    this.trackEvent('complete_wizard');
  }

  publishStore(): void {
    this.trackEvent('publish_store');
  }

  private trackEvent(name: ExternalEventName): void {
    if (!this.appConfig?.production) {
      return;
    }

    this.gtmService.pushTag({ event: name });
    this.pixel.trackCustom(name);
    this.appInsights.trackEvent({ name });
  }
}
