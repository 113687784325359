import { Injectable } from '@angular/core';
import { CrudService } from '../crud.service';
import { ApiResponse, ProductCategory, StoreItemLanguageContent } from '../../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends CrudService<ProductCategory> {
  basePath = '/v1/stores/categories';

  constructor() {
    super();
  }

  getLanguageContents(id: string): Observable<StoreItemLanguageContent[]> {
    return this.apiService.get(`${this.basePath}/${id}/languages`)
      .pipe(map((resp: ApiResponse) => resp.data));
  }
}
