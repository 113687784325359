import { Injectable } from '@angular/core';
import { CrudService } from "../crud.service";
import { Brand } from '../../new-models/us-models';

@Injectable({
  providedIn: 'root'
})
export class BrandService extends CrudService<Brand> {
  basePath = '/v1/stores/brands';

  constructor() {
    super();
  }

}
