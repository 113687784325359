import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Sale } from '../models';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class SaleService extends CrudService<Sale> {
  protected basePath = '/v1/stores/sales';

  constructor() {
    super();
  }

  toggle(id: string, state: boolean): Observable<Sale> {
    return this.apiService.patch(`${this.basePath}/${id}`, { isEnabled: state })
      .pipe(map((resp: any) => new Sale().fromSource(resp.data)));
  }
}
