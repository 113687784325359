import { GuidName } from './guid-name';

export enum VoucherType {
  DiscountOnly = 'DiscountOnly',
  FreeLocalDelivery = 'FreeLocalDelivery',
}

export enum VoucherStatus {
  Active = 'Active',
  Disabled = 'Disabled',
  Expired = 'Expired',
  Used = 'Used',
  Upcoming = 'Upcoming',
}

export enum VoucherDiscountType {
  Percentage = 'Percentage',
  Value = 'Value',
}

export enum AllowedCustomer {
  AllCustomers = 'AllCustomers',
  RepeatCustomers = 'RepeatCustomers',
  NewCustomers = 'NewCustomers',
  SpecificCustomer = "SpecificCustomer",
}

export enum LimitPerCustomer {
  Unlimited = 'Unlimited',
  Once = 'Once',
}

export class VoucherDiscount {
  type: VoucherDiscountType;
  value: number;
}

export class Voucher {
  allowedCustomers: AllowedCustomer;
  categories?: GuidName[];
  code?: string;
  customerId?: string;
  discount?: VoucherDiscount;
  expiryDate?: string;
  id: string;
  limitPerCustomer: LimitPerCustomer;
  maxUses?: number;
  name?: string;
  numberOfTimesRedeemed: number;
  onlyForSubtotalsOver?: number;
  products?: GuidName[];
  startDate?: string;
  status: VoucherStatus;
  type: VoucherType;
}

export class VoucherRequest {
  allowedCustomers: AllowedCustomer;
  categories?: string[];
  code?: string;
  customerId?: string;
  discount: VoucherDiscount;
  expiryDate?: string;
  limitPerCustomer: LimitPerCustomer;
  maxUses?: number;
  name?: string;
  onlyForSubtotalsOver?: number;
  products?: string[];
  startDate?: string;
  status: VoucherStatus;
  type: VoucherType;
}
