import { Injectable, Signal, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiGetParams, ApiResponse, Product, ProductReview, Sale } from '../../models';
import { CrudService } from '../crud.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends CrudService<Product> {
  protected basePath = '/v1/stores/products';

  #relatedProducts = signal<Product[]>([]);
  #sales = signal<Sale[]>([]);

  constructor() {
    super();
  }

  get relatedProducts(): Signal<Product[]> {
    return this.#relatedProducts.asReadonly();
  }

  set relatedProducts(v: Product[]) {
    this.#relatedProducts.set(v);
  }

  get sales(): Signal<Sale[]> {
    return this.#sales.asReadonly();
  }

  set sales(v: Sale[]) {
    this.#sales.set(v);
  }

  loadSales(id: string): void {
    this.getAllSales(id)
      .pipe(
        tap(sales => this.sales = sales),
      )
      .subscribe();
  }

  getAllSales(productId: string): Observable<Sale[]> {
    return this.apiService.get(`${this.basePath}/${productId}/sales`, { pageSize: '' })
      .pipe(
        map((resp: ApiResponse) => resp.data)
      );
  }

  loadRelatedProducts(id: string): void {
    this.getAllRelatedProducts(id)
      .pipe(
        tap(products => this.relatedProducts = products),
      )
      .subscribe();
  }

  getAllRelatedProducts(id: string): Observable<Product[]> {
    return this.apiService.get(`${this.basePath}/${id}/related-products`, { pageSize: '' })
      .pipe(
        map((resp: ApiResponse) => resp.data),
      );
  }

  getReviewsWithMeta(id: string, params?: ApiGetParams): Observable<ApiResponse<ProductReview[]>> {
    return this.apiService.get(`${this.basePath}/${id}/reviews`, params);
  }
}
