import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Website } from '../../new-models/us-models';
import { AppService } from '../../new-services/us-services';
import { ApiKeyService } from '../../services';
import { AuthService, StoreService, WebsiteService } from '../api-us-services';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractInitDataService {
  dialog = inject(MatDialog);

  #router = inject(Router);
  #appService = inject(AppService);
  #authService = inject(AuthService);
  #storeService = inject(StoreService);
  #websiteService = inject(WebsiteService);
  #apiKeyService = inject(ApiKeyService);

  protected abstract showErrorModal(title: string, errorMsg: string): void;

  initWebsite(): void {
    this.#apiKeyService.removeKey();

    this.#websiteService.loadCurrent()
      .pipe(
        catchError(err => {
          this.navigateToComingSoon();
          return err;
        }),
        //tap((website: Website) => {
        //
        //
        //
        //  //if (!website.apiKey || !website.isEnabled) {
        //  //  return of([ null, null ]);
        //  //}
        //
        //  //this.websiteStore.current = website;
        //  //this.apiKeyService.apiKey = website.apiKey;
        //  //this.appService.setAppFavicon(website.favicon);
        //  //this.languageService.initLang();
        //
        //  //this.trialModeService.isInTrialMode = website.isTrialSubscription;
        //  //
        //  //this.populateData();
        //  //this.initAnalytics();
        //
        //  //return forkJoin([
        //  //  this.storeService.findCurrent(),
        //  //  this.storeService.getAboutUs(),
        //  //]);
        //}),
      )
      .subscribe((website: Website) => {
        if (!website.apiKey || !website.isEnabled) {
          this.navigateToComingSoon();
          return;
        }

        this.#appService.setWebsiteInfo(website);

        if (this.#apiKeyService.apiKey !== website.apiKey) {
          this.#apiKeyService.apiKey = website.apiKey;
        }

        this.#storeService.loadCurrent().subscribe();

        this.handleAuth();

        //if (!store) {
        //  this.router.navigate([ 'coming-soon' ]);
        //  return;
        //}
        //
        //const website = this.websiteStore.current;
        //
        //this.seoService.createMetadata({
        //  site_name: store.name || website.name,
        //  title: store.name || website.name,
        //  description: store.aboutUsEnabled ? aboutUs.content : '',
        //  image: store?.logo?.url || website?.favicon
        //}, true);
        //
        //this.shopStore.current = store;
        //this.shopStoreCore.current = store;
      });
  }

  //populateData(): void {
  //  this.storeService.getAboutUs()
  //    .subscribe((aboutUs) => this.aboutUsStore.current = aboutUs);
  //
  //  this.categoryService.allPages()
  //    .subscribe((categories) => this.categoryStore.list = categories);
  //
  //  this.configService.getCountries()
  //    .subscribe(countries => this.configService.countries = countries);
  //
  //  combineLatest([ this.websiteService.getStyle(), this.isEmbedded$ ])
  //    .subscribe(([ styles, isEmbedded ]) => {
  //      const embeddedStyles = this.#embeddedService.styles;
  //
  //      this.styleStore.current = { ...styles, ...(isEmbedded ? embeddedStyles : {}) };
  //    });
  //
  //  this.websiteService.getLanguages()
  //    .subscribe(languages => this.languageStore.list = languages);
  //
  //  this.authService.getLoginProviders()
  //    .subscribe(providers => this.loginProviderStore.list = providers);
  //
  //  this.shippingService.getConfig()
  //    .subscribe(config => this.shippingStore.current = config);
  //}
  //
  //initAnalytics(): void {
  //  this.analyticsService.getCodes().subscribe((codes: AnalyticsCode[]) => {
  //    codes.forEach((code: AnalyticsCode) => {
  //      const scriptEl = new DOMParser().parseFromString(code.script, 'text/html').querySelector('script');
  //      const scriptInnerText = scriptEl.innerText;
  //      const scriptSrc = scriptEl.src;
  //      const isAsync = scriptEl.async;
  //      const script = this.renderer.createElement('script');
  //
  //      this.renderer.setAttribute(script, 'title', code.name);
  //      this.renderer.setAttribute(script, 'type', 'text/javascript');
  //
  //      if (isAsync) {
  //        this.renderer.setAttribute(script, 'async', 'async');
  //      }
  //
  //      if (scriptSrc) {
  //        this.renderer.setAttribute(script, 'src', scriptSrc);
  //      }
  //
  //      if (scriptInnerText) {
  //        this.renderer.setProperty(script, 'innerText', scriptInnerText);
  //      }
  //
  //      // @todo
  //      this.renderer.appendChild(this.document.head, script);
  //
  //      if (code.name === 'Google Analytics') {
  //        const additionalScript = this.renderer.createElement('script');
  //
  //        const apiKey = scriptSrc.split('?id=')[ 1 ];
  //        this.renderer.setProperty(additionalScript, 'innerText', `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${apiKey}');`)
  //
  //        // @todo
  //        this.renderer.appendChild(this.document.head, additionalScript);
  //      }
  //    });
  //  });
  //}

  private handleAuth(): void {
    // using pure js instead of angular route, because of https://github.com/angular/angular/issues/12157
    const urlParams = new URLSearchParams(window.location.search);

    const errorMsg = urlParams.get('error_message');
    const accessToken = urlParams.get('access_token');
    const tokenExpiry = urlParams.get('expiry');
    const isSocialAuth = errorMsg || (accessToken && tokenExpiry);

    if (!isSocialAuth) {
      this.#authService.populate();
      return;
    }

    if (errorMsg) {
      const error = decodeURI(errorMsg);
      this.showErrorModal($localize`Error`, error);

    } else if (accessToken && tokenExpiry) {
      this.#authService.processToken({ access_token: accessToken, expiry: tokenExpiry });
      this.#router.navigate([ '/' ]);
    }
  }

  private navigateToComingSoon(): void {
    this.#apiKeyService.removeKey();
    this.#router.navigate([ 'coming-soon' ]);
  }
}
