import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Address } from '../models';

import { BoxValidators } from '../utils';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import PlaceResult = google.maps.places.PlaceResult;

@Injectable({
  providedIn: 'root'
})
export class AddressFormService {
  addressFields = Object.keys(new Address()) as Array<keyof Address>;

  requiredFields: Array<keyof Address> = [ 'addressLine1', 'countryCode', 'latitude', 'longitude' ];

  constructor(
    private geocoder: MapGeocoder,
  ) { }

  getFormGroup(fullAddressValidation = true): FormGroup {
    const group = new FormGroup({});

    this.addressFields.forEach((field) => {
      const validator = this.requiredFields.includes(field) ? Validators.required : null;
      const control = new FormControl('', validator);

      group.addControl(field, control);
    });

    if (fullAddressValidation) {
      group.setValidators(BoxValidators.fullAddressValidator());
    }

    return group;
  }

  // todo refactor
  getAddressFromGeocoder(resp: MapGeocoderResponse): Address {
    const mainPlace = resp.results.find(({ types }) =>
      types.includes('street_address') || types.includes('route') || resp.results[ 0 ]
    );

    const place: PlaceResult = {
      // union all address components
      ...mainPlace,
      address_components: resp.results.reduce((res, v) => [ ...res, ...v.address_components ], []),
    }

    return this.getAddressFromGooglePlace(place);
  }

  getAddressFromGooglePlace(place: PlaceResult): Address {
    const { address_components: components, geometry } = place;
    const getComponentValue = (name: string, type: 'long' | 'short' = 'long'): string => {
      const comp = getComponent(components, name);

      return comp ? comp[ `${type}_name` ] : '';
    };
    const streetName = getComponentValue('route');
    const houseNumber = getComponentValue('street_number');

    return {
      addressLine1: getAddressLine1(houseNumber, streetName),
      addressLine2: '',
      city: getComponentValue('locality') || getComponentValue('postal_town') || getComponentValue('administrative_area_level_2'),
      country: getComponentValue('country'),
      countryCode: getComponentValue('country', 'short'),
      formattedAddress: place.formatted_address,
      latitude: geometry?.location.lat() ?? '',
      longitude: geometry?.location.lng() ?? '',
      placeId: place.place_id,
      placeName: place.name,
      postalCode: getComponentValue('postal_code'),
      province: getComponentValue('administrative_area_level_1'),
      suburb: getComponentValue('sublocality'),
    };
  }

  // todo create separate map component
  getMapCenter(country: { code: string, name: string }): Observable<google.maps.LatLng> {
    return this.geocoder.geocode({ address: country.name, region: country.code })
      .pipe(
        filter(resp => resp.status === 'OK'),
        map(resp => resp.results[ 0 ]?.geometry.location),
      );
  }

  isAddressValid(address: Address): boolean {
    return this.requiredFields.every(f => !!address[ f ]);
  }
}

function getComponent(components: GeocoderAddressComponent[], type: string): GeocoderAddressComponent {
  return components?.find(c => c.types.includes(type));
}

function getAddressLine1(house: string, street: string): string {
  return [ house, street ].filter(i => !!i).join(' ');
}
