import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {
  private readonly storageKeyName = 'box-api-key';
  private apiKeyPr: string;

  constructor() { }

  set apiKey(key: string) {
    localStorage.setItem(this.storageKeyName, key);
    this.apiKeyPr = key;
  }

  get apiKey() {
    return this.apiKeyPr || localStorage.getItem(this.storageKeyName);
  }

  removeKey(): void {
    this.apiKeyPr = null;
    localStorage.removeItem(this.storageKeyName);
  }
}
