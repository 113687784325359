import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractAppService {

  protected constructor() { }

  abstract navigateToLogin(): Observable<boolean>;
}
