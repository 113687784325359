import { isArray, isObject, isPlainObject } from 'lodash-es';

export function transformData(data: any, func: (str: string) => string): any {
  if (isArray(data)) {
    return data.map(object => transformData(object, func));

  } else if (isPlainObject(data)) {
    const result = {};

    Object.keys(data).forEach(key => {
      const value = data[ key ];

      result[ func(key) ] = isObject(value) ? transformData(value, func) : value;
    });

    return result;

  } else {
    return data;
  }
}