import { UserPermission } from '../models';

export type OrderPatchAction =
  'cancel'
  | 'refund'
  | 'arrange-collection'
  | 'capture-delivery'
  | 'mark-as-paid'
  | 'complete'
  | 'cancel/delivery'
  | 'edit'
  | 'download-invoice';

export interface OrderAction {
  label: string;
  patchAction: OrderPatchAction;
  permission?: UserPermission;
}

export const actions: OrderAction[] = [
  {
    label: $localize `Mark as paid`,
    patchAction: 'mark-as-paid',
    permission: 'orders.details.manage',
  },
  {
    label: $localize `Mark as complete`,
    patchAction: 'complete',
    permission: 'orders.details.manage',
  },
  {
    label: $localize `Refund`,
    patchAction: 'refund',
    permission: 'orders.refund.manage',
  },
  {
    label: $localize `Arrange collection`,
    patchAction: 'arrange-collection',
    permission: 'orders.delivery.manage',
  },
  {
    label: $localize `Capture delivery details`,
    patchAction: 'capture-delivery',
    permission: 'orders.delivery.manage',
  },
  {
    label: $localize `Cancel order`,
    patchAction: 'cancel',
    permission: 'orders.details.manage',
  },
  {
    label: $localize `Cancel collection`,
    patchAction: 'cancel/delivery',
    permission: 'orders.delivery.manage',
  },
  {
    label: $localize `Edit order`,
    patchAction: 'edit',
    permission: 'orders.details.manage',
  },
];
