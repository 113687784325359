import { computed, Injectable, Signal } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, takeWhile } from 'rxjs/operators';

import { Store, User, UserPermission, UserRole } from '../models';
import { ShopStoreService, UserStoreService } from '../stores';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {
  private systemAdminRole: UserRole = 'boxcommerce-system-admin';
  private adminRoles: UserRole[] = [ 'owner', 'admin', this.systemAdminRole ];

  constructor(
    private userStore: UserStoreService,
    private shopStore: ShopStoreService,
  ) { }

  get currentUser$(): Observable<User> {
    return this.userStore.current$
      .pipe(
        filter(user => !!user),
      );
  }

  get currentUserSnapshot(): User {
    return this.userStore.current;
  }

  isActionAllowed(action: UserPermission | ''): Observable<boolean> {
    return this.currentUser$
      .pipe(
        filter(user => !!user.permissions),
        map(user => this.isAdmin(user) || (action && user.permissions.includes(action))),
      );
  }

  $isActionAllowed(perm?: UserPermission): Signal<boolean> {
    return computed(() => {
      const user = this.userStore.currentS();

      return this.isActionAllowedByUser(user, perm);
    });
  }

  isActionAllowedByUser(user: User, perm?: UserPermission): boolean {
    return !!user?.permissions ? (this.isAdmin(user) || (!!perm && user.permissions.includes(perm))) : false;
  }

  isActionAllowedSnapshot(action: UserPermission | ''): boolean {
    const user = this.currentUserSnapshot;

    return this.isAdmin(user) || (action && user.permissions.includes(action));
  }

  isSystemAdmin(user: User): boolean {
    return user.roles.includes(this.systemAdminRole);
  }

  // todo move to separate service
  checkContactDetails(): Observable<string[]> {
    return combineLatest([
      this.shopStore.current$,
      this.userStore.current$,
    ])
      .pipe(
        filter(([ store, user ]: [ Store, User ]) => !!store && !!user && !!user.roles),
        take(1),
        takeWhile(([ , user ]) => !this.isSystemAdmin(user)),
        map(([ store, user ]: [ Store, User ]) => ([
          ...(!user.firstName || !user.lastName ? [ 'firstName', 'lastName' ] : []),
          ...(!store.contactNumber ? [ 'contactNumber' ] : []),
        ])),
        takeWhile((missingFields: string[]) => !!missingFields.length),
      );
  }

  private isAdmin(user: User): boolean {
    return this.adminRoles.some(adminRole => user.roles.includes(adminRole));
  }
}
