import { Injectable } from '@angular/core';
import { Product, ProductVariation } from '../../models';
import { CrudService } from '../../api-services';

@Injectable({
  providedIn: 'root'
})
export class ProductPageService extends CrudService<ProductPageService> {
  availableStatus = 'Available';

  constructor() {
    super();
  }

  isAvailable(stockStatus: string): boolean {
    return stockStatus === this.availableStatus;
  }

  getRelatedVariation(product: Product, variation: ProductVariation, attrName: string, attrVal: string): ProductVariation {
    return product.variations.find(v => v.attributes.every(
      a => a.name === attrName ? a.value === attrVal : a.value === this.getAttributeValue(variation, a.name)
    ));
  }

  getVariationsByAttribute(product: Product, attrName: string, attrVal: string): ProductVariation[] {
    return product.variations.filter(v => v.attributes.some(a => a.name === attrName && a.value === attrVal));
  }

  getAttributeValue(variation: ProductVariation, name: string): string {
    return variation?.attributes.find(a => a.name === name)?.value;
  }
}
