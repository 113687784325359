import { SaleThreshold } from './sale-threshold';
import { BaseModel } from './base-model';
import { GuidName } from './guid-name';
import { SaleDiscount } from './sale-discount';
import { SaleImage } from './sale-image';
import { SaleRibbon } from './sale-ribbon';
import { SaleType } from './sale-type';
import { SaleStatus } from './sale-status';

export class Sale extends BaseModel {
  name: string;
  startDate: string;
  expiryDate: string;
  type: SaleType;
  showPreview: boolean;
  showCountdown: boolean;
  status?: SaleStatus;
  ribbon?: SaleRibbon;
  sticker?: SaleRibbon;
  banner?: SaleImage;
  threshold?: SaleThreshold;
  discount?: SaleDiscount;
  limitPerCustomer?: number;
  limitIndividualProductQuantityPerCustomer?: number;
  categories?: GuidName[];
  products?: { productId: string; variationId?: string; }[];
  excludedProducts?: { productId: string; variationId?: string; }[];
  freeCategories?: GuidName[];
  freeProducts?: { productId: string; variationId?: string; }[];
  excludedFreeProducts?: { productId: string; variationId?: string; }[];
}
