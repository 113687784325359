import { computed, inject, Injectable, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginGroup, LoginProvider } from '../../new-models/us-models';
import { CrudService } from '../crud.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerLoginService extends CrudService<LoginProvider> {
  basePath = '/v1/login/providers';

  filteredOauthProviders: Signal<LoginProvider[]>;
  filteredProviders: Signal<LoginProvider[]>;
  dataLoaded: Signal<boolean>;
  loginGroups: Signal<LoginGroup[]>;
  excludedProviders = [ 'Twitter', 'X' ];

  #customerService = inject(CustomerService);

  constructor() {
    super();

    this.filteredProviders = computed(() =>
      this.list().filter(provider => !this.excludedProviders.includes(provider.name))
    );

    this.filteredOauthProviders = computed(() =>
      this.filteredProviders().filter(provider => provider.type === 'OAuth')
    );

    this.dataLoaded = computed(() => this.listLoaded() && this.#customerService.loginAccountListLoaded());

    this.loginGroups = computed(() => {
      const providers = this.filteredProviders();
      const accounts = this.#customerService.loginAccountList();

      // do we need to check for accounts without providers there?
      return providers.map(provider => ({
        provider,
        account: accounts.find(a => a.provider === provider.name),
      }));
    });
  }

  all(): Observable<LoginProvider[]> {
    return this.apiService.get(this.basePath);
  }

  initData(): void {
    this.initList();
    this.#customerService.initLoginAccountList();
  }
}
