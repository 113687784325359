import { Injectable, Signal, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer, LoginAccount } from '../../new-models/us-models';
import { CrudService } from '../index';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends CrudService<Customer> {
  basePath = '/v1/customers';
  currentPath = 'current';
  passwordPath = 'password';
  accountsPath = 'provider/accounts';

  #loginAccountList = signal<LoginAccount[]>([]);
  #loginAccountListLoaded = signal<boolean>(false);

  constructor() {
    super();
  }

  set loginAccountList(v: LoginAccount[]) {
    this.#loginAccountList.set(v);
  }

  get loginAccountList(): Signal<LoginAccount[]> {
    return this.#loginAccountList.asReadonly();
  }

  set loginAccountListLoaded(v: boolean) {
    this.#loginAccountListLoaded.set(v);
  }

  get loginAccountListLoaded(): Signal<boolean> {
    return this.#loginAccountListLoaded.asReadonly();
  }

  loadLoginAccountList(): void {
    this.loginAccountListLoaded = false;

    this.getAccounts()
      .subscribe(list => {
        this.loginAccountList = list;
        this.loginAccountListLoaded = true;
      });
  }

  initLoginAccountList(): void {
    if (!this.loginAccountListLoaded()) {
      this.loadLoginAccountList();
    }
  }

  updateLoginAccountInList(data: LoginAccount, prop = 'provider') {
    this.#loginAccountList.update(list =>
      list.map(item => item[ prop ] === data[ prop ] ? { ...item, ...data } : item)
    );
  }

  changePassword(data: { currentPassword: string, newPassword: string, confirmPassword: string }) {
    return this.apiService.put(`${this.basePath}/${this.currentPath}/${this.passwordPath}`, data)
      .pipe(
        map(resp => resp.data),
      );
  }

  getAccounts(): Observable<LoginAccount[]> {
    return this.apiService.get(`${this.basePath}/${this.currentPath}/${this.accountsPath}`)
      .pipe(
        map(resp => resp.data)
      );
  }

  disableAccount(providerName: string): Observable<LoginAccount> {
    return this.apiService.patch(`${this.basePath}/${this.currentPath}/${this.accountsPath}/${providerName}/disable`, null)
  }
}
