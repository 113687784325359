import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppConfig, BOX_APP_CONFIG } from '../common-components.config';
import { ApiGetParams, ReportInputData, ReportType } from '../models';
import { ApiKeyService } from './api-key.service';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl: string;

  constructor(
    private http: HttpClient,
    private apiKeyService: ApiKeyService,
    private fileService: FileService,
    @Optional() @Inject(BOX_APP_CONFIG) private appConfig?: AppConfig,
  ) {
  }

  get apiKey(): string {
    return this.apiKeyService.apiKey;
  }

  get url(): string {
    return `${this.baseUrl}/${this.apiKey}/api`;
  }

  get urlAnon(): string {
    return `${this.baseUrl}/api`;
  }

  get baseUrl(): string {
    return `${this.apiUrl ?? this.appConfig?.apiUrl}`;
  }

  get(path, params?: ApiGetParams): Observable<any> {
    return this.http.get(`${this.url}${path}`, { responseType: 'json', params });
  }

  getAnon(path, params?: ApiGetParams): Observable<any> {
    return this.http.get(`${this.urlAnon}${path}`, { responseType: 'json', params });
  }

  getBase(path, params?: ApiGetParams, headers?: HttpHeaders | {
    [ header: string ]: string | string[];
  }): Observable<any> {
    return this.http.get(`${this.baseUrl}${path}`, { responseType: 'json', params, headers });
  }

  getBlob(path, params?: { [ param: string ]: string | string[]; }): Observable<any> {
    return this.http.get(`${this.url}${path}`, { responseType: 'blob', params });
  }

  getText(path, params?: { [ param: string ]: string | string[]; }): Observable<any> {
    return this.http.get(`${this.url}${path}`, { responseType: 'text', params });
  }

  post(path, body: any | null, options?: any): Observable<any> {
    return this.http.post(`${this.url}${path}`, body, options);
  }

  postBase(path, body: any | null, options?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}${path}`, body, options);
  }

  patch<T>(path, body: any | null, options?: { [ key: string ]: any, observe: 'body' }): Observable<any> {
    return this.http.patch<T>(`${this.url}${path}`, body, options);
  }

  put<T>(path, body: any | null, options?: any): Observable<any> {
    return this.http.put(`${this.url}${path}`, body, options);
  }

  delete(path): Observable<any> {
    return this.http.delete(`${this.url}${path}`);
  }

  deleteWithBody(path, body): Observable<any> {
    return this.http.delete(`${this.url}${path}`, { body: body });
  }

  download(path, params?: { [ param: string ]: string | string[]; }): Observable<any> {
    return this.http.get(`${this.url}${path}`, { responseType: 'blob', params });
  }

  export(path, { reportType, fileName, params }: ReportInputData): Observable<string | Blob> {
    const mergedParams = { reportType, ...params };
    const reportRequest = {
      [ ReportType.CSV ]: this.exportCsv(path, mergedParams, fileName),
      [ ReportType.Excel ]: this.exportExcel(path, mergedParams, fileName)
    };

    return reportRequest[ reportType ];
  }

  private exportCsv(path, params: { [ key: string ]: string | string[] }, fileName: string): Observable<string> {
    return this.getText(path, params).pipe(tap((csvContent: string) => this.fileService.downloadCsv(fileName, csvContent)));
  }

  private exportExcel(path, params: { [ key: string ]: string | string[] }, fileName: string): Observable<Blob> {
    return this.getBlob(path, params).pipe(tap((excel: Blob) => this.fileService.downloadExcel(fileName, excel)));
  }
}
