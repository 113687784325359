import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseStoreService<T> {
  private currentSubject = new BehaviorSubject<T | null>(null);
  current$ = this.currentSubject.asObservable();

  private listSubject = new BehaviorSubject<T[]>([]);
  list$ = this.listSubject.asObservable();

  currentS: Signal<T>;
  listS: Signal<T[]>;

  protected constructor() {
    this.currentS = toSignal(this.currentSubject);
    this.listS = toSignal(this.listSubject);
  }

  set current(item: T | null) {
    this.currentSubject.next(item);
  }

  get current(): T | null {
    return this.currentSubject.value;
  }

  set list(list: T[]) {
    this.listSubject.next(list);
  }

  get list(): T[] {
    return this.listSubject.value;
  }

  updateCurrent(vals: Partial<T>): void {
    this.current = { ...this.current, ...vals };
  }

  addToList(item: T | T[]): void {
    this.list = this.list.concat(item);
  }

  updateInList(item: T, prop = 'id'): void {
    const index = this.list.findIndex(i => i[ prop ] === item[ prop ]);

    this.list = Object.assign([ ...this.list ], { [ index ]: item });
  }

  removeFromList(id: string, prop = 'id'): void {
    this.list = this.list.filter(i => i[ prop ] !== id);
  }

  getByFromList(val: string | number, prop = 'id'): Observable<T> {
    return this.list$
      .pipe(map(list => list.find(i => i[ prop ] === val)));
  }

  updateOrAddToList(item: T, prop = 'id'): void {
    this.list.some(i => i[ prop ] === item[ prop ]) ? this.updateInList(item, prop) : this.addToList(item);
  }
}
