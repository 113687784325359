import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export type ProgressSpinnerMode = 'determinate' | 'indeterminate';

@Component({
  selector: 'us2-progress-spinner',
  standalone: true,
  imports: [],
  templateUrl: './progress-spinner.component.html',
  styleUrl: './progress-spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSpinnerComponent implements OnInit {
  @Input() color: string;
  @Input() substrateColor = '#e1e2e6';
  @Input() substrateOpacity = 1;
  indeterminateValue = 25;
  baseStrokeWidth = 8;
  private _diameter = 60;
  private _value = 0;
  private _strokeWidth: number;

  @Input()
  get diameter(): number { return this._diameter; }

  set diameter(size: number) {
    this._diameter = coerceNumberProperty(size);
  }

  @Input()
  get strokeWidth(): number {
    return this._strokeWidth || this.diameter / 8;
  }

  set strokeWidth(value: number) {
    this._strokeWidth = coerceNumberProperty(value);
  }

  @Input() mode: ProgressSpinnerMode = 'indeterminate';

  @Input()
  get value(): number {
    return this.mode === 'determinate' ? this._value : this.indeterminateValue;
  }

  set value(newValue: number) {
    this._value = Math.max(0, Math.min(100, coerceNumberProperty(newValue)));
  }

  get circleRadius(): number {
    return (this.diameter - this.baseStrokeWidth) / 2;
  }

  get viewBox(): string {
    const viewBox = this.circleRadius * 2 + this.strokeWidth;
    return `0 0 ${viewBox} ${viewBox}`;
  }

  get strokeCircumference(): number {
    return 2 * Math.PI * this.circleRadius;
  }

  get strokeDashOffset(): number {
    return this.strokeCircumference * (100 - this.value) / 100;
  }

  get circleStrokeWidth(): number {
    return this.strokeWidth / this.diameter * 100;
  }

  constructor() { }

  ngOnInit(): void {
  }
}
