export enum TimelineStepType {
  Received = 'received',
  Processed = 'processed',
  ReadyForCollection = 'readyForCollection',
  Collected = 'collected',
  Dispatched = 'dispatched',
  Refunded = 'refunded',
  PartiallyRefunded = 'partiallyRefunded',
  HasIssue = 'hasIssue',
  Completed = 'completed',
}
