export class BaseModel {
  id?: string;

  protected childEntities?: { [ key: string ]: any };

  fromSource?(src: any) {
    if (!src) {
      return this;
    }

    Object.keys(src).forEach(key => {
      const entity = this.childEntities && this.childEntities[ key ];
      const prop = src[ key ];


      if (prop && entity) {
        this[ key ] = Array.isArray(prop) ? prop.map(i => new entity().fromSource(i)) : new entity().fromSource(prop);
      } else {
        this[ key ] = prop;
      }
    });

    return this;
  }
}
