import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { CountryConfig, CurrencyConfig, DimensionUnit, UnitSystem } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  basePath = '/v1/config';
  private maxPageSize = 1000;

  private currenciesSubject = new BehaviorSubject<CurrencyConfig[]>([]);
  currencies$ = this.currenciesSubject.asObservable();

  private countriesSubject = new BehaviorSubject<CountryConfig[]>([]);
  countries$ = this.countriesSubject.asObservable();

  private allowedCountriesSubject = new BehaviorSubject<CountryConfig[]>([]);
  allowedCountries$ = this.allowedCountriesSubject.asObservable();

  private taxTypesSubject = new BehaviorSubject<string[]>([]);
  taxTypes$ = this.taxTypesSubject.asObservable();

  $countries = toSignal(this.countries$);

  constructor(
    private apiService: ApiService,
  ) {
  }

  set currencies(val: CurrencyConfig[]) {
    this.currenciesSubject.next(val);
  }

  get currencies(): CurrencyConfig[] {
    return this.currenciesSubject.value;
  }

  set countries(val: CountryConfig[]) {
    this.countriesSubject.next(val);
  }

  get countries(): CountryConfig[] {
    return this.countriesSubject.value;
  }

  set allowedCountries(val: CountryConfig[]) {
    this.allowedCountriesSubject.next(val);
  }

  get allowedCountries(): CountryConfig[] {
    return this.allowedCountriesSubject.value;
  }

  set taxTypes(val: string[]) {
    this.taxTypesSubject.next(val);
  }

  get taxTypes(): string[] {
    return this.taxTypesSubject.value;
  }

  // todo use Signals?
  getCountryByCode(code: string): Observable<CountryConfig> {
    return this.countries$.pipe(
      filter(c => c.length > 0),
      take(1),
      map(countries => countries.find(c => c.code === code)),
    );
  }

  getCurrencies(): Observable<CurrencyConfig[]> {
    return this.apiService.getAnon(`${this.basePath}/currencies`, { pageSize: this.maxPageSize })
      .pipe(
        map(resp => resp.data),
      );
  }

  getLengths(unitType: UnitSystem): Observable<DimensionUnit[]> {
    return this.apiService.getAnon(`${this.basePath}/lengths`, { unitType })
      .pipe(
        map(resp => resp.data),
      );
  }

  getWeights(unitType: UnitSystem): Observable<DimensionUnit[]> {
    return this.apiService.getAnon(`${this.basePath}/weights`, { unitType })
      .pipe(
        map(resp => resp.data),
      );
  }

  getCountries(): Observable<CountryConfig[]> {
    return this.apiService.getAnon(`${this.basePath}/countries`, { pageSize: this.maxPageSize })
      .pipe(
        map(resp => resp.data),
      );
  }

  getAllowedCountries(): Observable<CountryConfig[]> {
    return this.apiService.getAnon(`${this.basePath}/countries/allowed`, { pageSize: this.maxPageSize })
      .pipe(
        map(resp => resp.data),
      );
  }

  getTaxTypes(): Observable<string[]> {
    return this.apiService.getAnon(`${this.basePath}/tax-types`, { pageSize: this.maxPageSize })
      .pipe(
        map(resp => resp.data),
      );
  }

  getCurrency(code: string) {
    return this.apiService.getAnon(`${this.basePath}/currencies/${code}`)
      .pipe(
        map(resp => resp.data),
      );
  }

  getCountry(code: string) {
    return this.apiService.getAnon(`${this.basePath}/countries/${code}`)
      .pipe(
        map(resp => resp.data),
      );
  }
}
