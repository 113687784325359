import { computed, inject, Injectable, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DEFAULT_LANGUAGE_CODE } from '../data/config-constants';
import { ApiResponse, LanguageCode, LanguageContent, Website } from '../models';
import { CrudService } from './crud.service';
import { WebsiteLanguageService } from './website-language.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService extends CrudService<Website> {
  protected basePath = '/v1/websites';
  defaultLang = DEFAULT_LANGUAGE_CODE;

  #langService = inject(WebsiteLanguageService);

  constructor() {
    super();
  }

  get preferredLangCode(): Signal<LanguageCode> {
    return computed(() => this.current()?.preferredSiteLanguage?.code ?? this.defaultLang);
  }

  get langsForTranslate(): Signal<any> {
    return computed(() => this.#langService.list()
      .filter(l => l.published && l.code !== this.preferredLangCode()));
  }

  checkSubDomain(domainName: string): Observable<{ available: boolean, domain: string }> {
    return this.apiService.get(`${this.basePath}/${this.currentPath}/domain`, { domainName })
      .pipe(
        map((resp: ApiResponse) => resp.data),
      );
  }

  getTermsAndConditions(): Observable<LanguageContent> {
    return this.apiService.get(`${this.basePath}/website/terms-and-conditions`)
      .pipe(
        map(resp => resp.data),
      );
  }
}
