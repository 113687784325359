import { Injectable } from '@angular/core';
import chroma from 'chroma-js';

export type ThemePalette = 'primary' | 'accent' | 'warn' | undefined;

export interface ColorHue {
  name: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaletteService {
  defaultColors = {
    primary: '#c671e2',
    accent: '#1dc4e9',
  };

  private prefix = 'bc-website';

  constructor() { }

  getAllPaletteStyles(primaryColor: string, accentColor: string) {
    primaryColor = primaryColor ?? this.defaultColors.primary;
    accentColor = accentColor ?? this.defaultColors.accent;

    return {
      ...this.getPaletteStyle(primaryColor, 'primary'),
      ...this.getPaletteStyle(accentColor, 'accent')
    };
  }

  getPaletteStyle(color: string, palette: ThemePalette) {
    const hues = getPaletteHues(color);

    const styleFactory = this.getStyleFactory(palette);

    return styleFactory(hues);
  }

  private getStyleFactory(palette: ThemePalette) {
    return (hues: ColorHue[]) => {
      return hues.reduce((res, hue) => {
        res[ `--${this.prefix}-${palette}-${hue.name}` ] = hue.color;
        return res;
      }, {});
    };
  }
}

function getPaletteHues(color: string) {
  const baseHues: ColorHue[] = [
    { name: '50', color: chroma(color).brighten(.52) },
    { name: '100', color: chroma(color).brighten(.37) },
    { name: '200', color: chroma(color).brighten(.26) },
    { name: '300', color: chroma(color).brighten(.12) },
    { name: '400', color: chroma(color).brighten(.06) },
    { name: '500', color: chroma(color) },
    { name: '600', color: chroma(color).darken(.06) },
    { name: '700', color: chroma(color).darken(.12) },
    { name: '800', color: chroma(color).darken(.18) },
    { name: '900', color: chroma(color).darken(.24) },
    { name: 'A100', color: chroma(color).brighten(.37).saturate(3) },
    { name: 'A200', color: chroma(color).brighten(.26).saturate(3) },
    { name: 'A400', color: chroma(color).brighten(.06).saturate(3) },
    { name: 'A700', color: chroma(color).darken(.12).saturate(3) }
  ].map(hue => ({ name: hue.name, color: hue.color.hex() }));

  return [ ...baseHues, ...baseHues.map(getContrastByHue) ];
}

function getContrastByHue(hue: ColorHue): ColorHue {
  return { name: `contrast-${hue.name}`, color: contrastColor(hue.color) };
}

export function contrastColor(color: string): string {
  if (!color) {
    return null;
  }

  return chroma(color).luminance() < 0.5 ? '#ffffff' : '#000000';
}

export function alphaColor(color: string, alpha: number): string {
  return chroma(color).alpha(alpha).hex();
}
