import { Injectable } from '@angular/core';

import { startCase } from 'lodash-es';
import { PASSWORD_SPECIAL_SYMBOLS } from '../data/config-constants';

@Injectable({
  providedIn: 'root'
})
export class ValidationMessageService {
  private messages = {
    required: name => $localize`The ${name} field is required.`,

    email: () => $localize`Please enter a valid email address.`,

    color: () => $localize`Please enter a valid hexcode.`,

    min: (name, error) => $localize`The ${name} must be at least ${error.min}.`,

    max: (name, error) => $localize`The ${name} may not be greater than ${error.max}.`,

    maxlength: (name, error) =>
      $localize`The ${name} may not be greater than ${error.requiredLength} characters.`,

    minlength: (name, error) =>
      $localize`The ${name} must be at least ${error.requiredLength} characters.`,

    mismatch: (name, error) => $localize`The ${name} does not match the ${getFriendlyName(error.control)}.`,

    url: name => $localize`The ${name} must be a valid URL.`,

    invalidAddress: () => $localize`Invalid address, please complete your address details manually.`,

    emptyAddress: () => $localize`Please complete your address details.`,

    weakPassword: name => $localize`The ${name} required at least 1 uppercase and 1 lowercase, 1 digit, 1 symbol (!@#$%^&*/), length from 8 to 64.`,

    validatePhoneNumber: () => $localize`Invalid number, please check and re-enter.`,

    voucherCode: name => $localize`The ${name} must contain only letters, numbers and symbols.`,

    matDatepickerMin: (name, error) => $localize`The ${name} value should be not earlier than ${error.min.toLocaleDateString('en-GB')}.`,

    matDatepickerMax: (name, error) => $localize`The ${name} value should be not later than ${error.max.toLocaleDateString('en-GB')}.`,

    minPercentage: name => $localize`The ${name} may not be less than 1%.`,

    maxPercentage: name => $localize`The ${name} may not exceed 100%.`,

    minLengthArray: (name, error) => $localize`The ${name} number may not be less than ${error.min}.`,

    maxLengthArray: (name, error) => $localize`The ${name} number may not exceed ${error.max}.`,

    maxUses: () => $localize`Available vouchers exceeded, Please add more or delete this field for unlimited vouchers.`,

    refundAmountValidator: () => $localize`The refund value cannot exceed the order value.`,

    customValidator: (name, error) => $localize`${error}`,

    server: (name, error) => error,
  };

  messages2 = {
    required: $localize`Field is required.`,
    email: $localize`Please enter a valid email address.`,
    weakPassword: $localize`The password required at least 1 uppercase and 1 lowercase, 1 digit, 1 symbol (${PASSWORD_SPECIAL_SYMBOLS}), length from 8 to 64.`,
    validatePhoneNumber: $localize`Invalid number, please check and re-enter.`,
    mismatch: $localize`The confirm password does not match the password.`,
  }

  constructor() { }

  getMessage(errorName: string, fieldName: string, error: any): string {
    return this.messages[ errorName ](fieldName, error);
  }
}

function getFriendlyName(name: string): string {
  return startCase(name).toLowerCase();
}
