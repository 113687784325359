import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models';
import { BaseStoreService } from './base-store.service';

/** @deprecated */
@Injectable({
  providedIn: 'root'
})
export class UserStoreService extends BaseStoreService<User> {
  constructor() {
    super();
  }

  get sortedList(): Observable<User[]> {
    return this.list$
      .pipe(
        map(users => users.sort(user => user.roles.includes('owner') ? -1 : 1)),
      );
  }
}
