import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AuthToken,
  LoginDto,
  ResetPasswordDto,
  SendOtpDto,
  SendOtpResponse,
  SignUpDto,
  ValidateOtpDto,
  ValidateOtpResponse
} from '../../new-models/us-models';
import { JwtService } from '../../new-services/common-services';
import { ApiService } from '../../services';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = '/v1';

  redirectUrl: string;

  #router = inject(Router);
  #apiService = inject(ApiService);
  #customerService = inject(CustomerService);
  #jwtService = inject(JwtService);

  constructor() { }

  get isGuest(): boolean {
    const token = this.#jwtService.token.accessToken;

    if (!token) {
      return false;
    }

    const payload = JSON.parse(atob(token.split('.')[ 1 ]));

    const roleKey = Object.keys(payload).find(k => k.endsWith('/role'));

    return payload[ roleKey ] === 'guest';
  }

  populate() {
    if (!this.#jwtService.hasValidToken) {
      this.purgeAuth();

      return;
    }

    this.#customerService.loadCurrent()
      .subscribe();
  }

  login(data: LoginDto): Observable<AuthToken> {
    return this.#apiService.post(`${this.baseUrl}/login`, data)
      .pipe(
        tap(token => this.processToken(token)),
      );
  }

  signUp(data: SignUpDto) {
    return this.#apiService.post(`${this.baseUrl}/register`, data)
      .pipe(
        tap(token => this.processToken(token)),
      );
  }

  signUpGuest(data: SignUpDto): Observable<AuthToken> {
    return this.#apiService.post(`${this.baseUrl}/register/guest`, data)
      .pipe(
        tap(token => this.processToken(token)),
      );
  }

  logout(): void {
    this.purgeAuth();
    this.#router.navigate([ '/' ]);
  }

  forgotPassword(data: SendOtpDto): Observable<SendOtpResponse> {
    return this.#apiService.post(`${this.baseUrl}/forgot-password`, data);
  }

  validateOtp(data: ValidateOtpDto): Observable<ValidateOtpResponse> {
    return this.#apiService.post(`${this.baseUrl}/validate-otp`, data);
  }

  resetPassword(data: ResetPasswordDto) {
    return this.#apiService.post(`${this.baseUrl}/reset-password`, data);
  }

  processToken(token: { access_token: string, expiry: string }) {
    this.#jwtService.token = { accessToken: token.access_token, expiry: token.expiry };
    this.populate();
  }

  private purgeAuth() {
    this.#customerService.setCurrent(null);
    this.#jwtService.destroyToken();
  }
}
