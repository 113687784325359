import { Injectable } from '@angular/core';

import { WebsiteLanguage } from '../models';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteLanguageService extends CrudService<WebsiteLanguage> {
  protected basePath = '/v1/websites/languages';

  constructor() {
    super();
  }
}
