import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { capitalize, isEmpty } from 'radash';


@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  processErrors(resp: HttpErrorResponse, form: UntypedFormGroup, isUpperFirst = false): string {
    const error = resp.error;

    if (!error) {
      return $localize`Server Error`;
    }

    const errors = error.errors;

    if (!errors || isEmpty(errors)) {
      const serverError = this.getServerError(resp);

      form.setErrors({ server: serverError });

      return serverError;
    }

    Object.keys(errors).forEach(key => {
      const field = form.get(isUpperFirst ? capitalize(key) : key);

      if (!field) {
        return;
      }

      field.setErrors({ server: errors[ key ]?.join(', ') });
      field.markAsTouched();
    });

    return '';
  }

  getServerError(resp: HttpErrorResponse): string {
    return resp.error.message || resp.error.detail;
  }
}
